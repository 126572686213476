@use "variables.scss" as *;

* {
    padding: 0;
    margin: 0;
    outline: none;
  }

  body, html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    color: $main-text-color;
    font-size: $main-text-font-size;
  }

  @font-face {
    font-family: 'proSansRegular';
    src: url(/assets/fonts/ProductSans-Regular.ttf);
  }

  @font-face {
    font-family: 'proSansMedium';
    src: url(/assets/fonts/ProductSans-Medium.ttf);
  }

  @font-face {
    font-family: 'proSansBold';
    src: url(/assets/fonts/ProductSans-Bold.ttf);
  }

  @font-face {
    font-family: 'homePageBOOK';
    src: url(/assets/fonts/HOMEPAGEBAUKASTEN-BOOK.OTF);
  }

  @font-face {
    font-family: 'homePageBold';
    src: url(/assets/fonts/HOMEPAGEBAUKASTEN-BOLD.TTF);
  }

  @font-face {
    font-family: 'arialRegular';
    src: url(/assets/fonts/Arial.TTF);
  }

  @font-face {
    font-family: 'arialBold';
    src: url(/assets/fonts/Arial-bold.TTF);
  }

  @font-face {
    font-family: 'hanRegular';
    src: url(/assets/fonts/Source-Han-Sans-Regular.otf);
  }
  @font-face {
    font-family: 'poppins';
    src: url(/assets/fonts/Poppins-Bold.ttf);
  }

  .bold {
    font-weight: bold!important;
  }

  .coupon-layout {
    max-width: 1920px;
    margin: 0 auto;
    .apps-main-title {
        color: $main-text-color;
        font-family: 'proSansBold', sans-serif;
        //margin-bottom: 28px;
        margin-top: 25px;
    }
    .show-coupon {
        position: relative;
    }
    .link-acc {
        position: relative;
      .steps {
          height: 57px;
          margin-top: 55px;
          &.with-middle-text {
            .line {
                width: 561px;
            }
            .step.middle-text {
                position: absolute;
                left: 50%;
            }
          }
          .step {
              display: inline-block;
              position: relative;
              .num {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  color: white;
                  padding-top: 2px;
                  text-align: center;
                  font-family: 'proSansBold',sans-serif;
              }
              .text {
                  position: absolute;
                  width: 220px;
                  left: -99px;
                  margin-top: 14px;
                  line-height: 21px;
                  font-family: 'proSansMedium',sans-serif;
              }
          }
          .step[isactive='false'].step1 {
              .num {
                  background: $mc-color;
                  &::before {
                      content: '';
                      width: 10px;
                      height: 9px;
                      display: inline-block;
                      background-repeat: no-repeat;
                  }
              }
              .text {
                  color:$diabled-text
              }
          }
          .line {
              width: 377px;
              display: inline-block;
              height: 4px;
              position: relative;
              top: -2px;
          }
      }

    }
    .adcore-logo {
        display: none;
        margin-top: 45px;
        a {
            position: absolute;
            left: 129px;
            i {
                background-image: url('/assets/icons/adcore-logo.svg');
                width: 102px;
                height: 25px;
                background-repeat: no-repeat;
                display: inline-block;
                background-size: 100%;
            }
        }
        &.white {
            i {
                background-image: url('/assets/icons/adcore-logo-white.svg');
            }
        }
      }
    &.microsoft-coupon {
        text-align: center;
        font-family: 'arialRegular' ,sans-serif;
      .microsoft-image {
          height: 690px;
          width: 100%;
          text-align: left;
          .image {
              background-image: url('/assets/images/mic-step1-l.png');
              width: 100%;
              height: 100%;
              display: inline-block;
              background-size: cover;
              background-repeat: no-repeat;
              padding-top: 81px;
              position: relative;
              z-index: 2;
              &.cn {
                background-image: url('/assets/images/mic-cn-step1-l.webp');
                .shadow {
                    background: transparent!important;
                }
              }
              .shadow {
                  mix-blend-mode: multiply;
                  height: 100%;
                  width: 100%;
                  position: absolute;
                  top: 0;
                  background: rgba(51,51,51,0.8);
                  z-index: -1;
              }
              .on-top-shadow {
                  position: absolute;
                  padding-left: 226px;
                  width: 100%;
                  height: 100%;
                  .microsoft-tag {
                      background-size: contain;
                      width: 245px;
                      height: 84px;
                      display: inline-block;
                      background-repeat: no-repeat;
                      &.partner {
                        background-image: url('/assets/images/microsoft-elite-badge-new.png');
                        margin-right: 15px;
                      }
                      &.global-parthner {
                        background-image: url('/assets/images/microsoft-global-parthner.png');
                      }
                  }
                    .content {
                      letter-spacing: -0.48px;
                      font-size: 48px;
                      color: white;
                      margin-top: 34px;
                      margin-bottom: 16px;
                      font-family: 'arialBold' ,sans-serif;
                    }
                    .microsoft-ad-text {
                        margin-top: 28px;
                        width: 555px;
                        display: inline-block;
                        color: #FFFFFF;
                        font-size: 16px;
                        line-height: 22px;
                        font-family: 'arialRegular' ,sans-serif;
                        a {
                            color: #FFFFFF;
                        }
                        .qBtn {
                            font-size: 16px;
                            background: transparent;
                            color: white;
                            border: none;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                    .mic-eligible-btn button {
                        margin-bottom: 19px;
                        font-size: 16px;
                        color: white;
                        background: transparent;
                        border: none;
                        cursor: pointer;
                        text-decoration: underline;
                        font-family: 'arialBold' ,sans-serif;
                    }
              }
          }
          .link-acc {
            .num {
                background: $mc-color-dark;
            }
            .line {
                background: $grey-text;
                width: 298px;
            }
            .steps {
                margin-top: 40px;
                height: 72px;
                position: relative;
                max-width: 500px;
                &.with-middle-text {
                    .line {
                        width: 433px;
                    }
                    .step.middle-text {
                        //left: calc(50% - 10px);
                        left: 50%;
                        .text {
                            width: 150px;
                            left: -29px;
                            text-align: left;
                        }
                    }
                }
            }
            .step {
                &.step2 {
                    .text {
                        margin-left: -30px;
                        left: -5px;
                    }
                }
                &.all-checked {
                    .num {
                        background: $mc-color-dark!important;
                        color: #FFFFFF!important;
                    }
                    .text {
                        color: #FFFFFF!important;
                    }
                }
                .text {
                    color: $main-text-color;
                    left: 0;
                }
                &[isactive='true'] {
                  .num {
                      background: $mc-color-dark;
                  }
                  .text {
                      color: #FFFFFF!important;
                  }
                }
                &.completed {
                  .num {
                      background: #00A4EF!important;
                      color: #FFFFFF;
                      &::before {
                          content: '';
                          background-image: url('/assets/icons/check-white.svg');
                          width: 10px;
                          height: 9px;
                          display: inline-block;
                          background-repeat: no-repeat;
                      }
                  }
                  .text {
                      color: #FFFFFF!important;
                  }
                  &.middle-text {
                    .text {
                        left: -19px!important;
                    }
                  }
                }
            }
            .step[isactive='false'] {
                .num {
                    background: $grey-text;
                    color: #000000;
                }
                .text {
                    color: $grey-text;
                }
                &.middle-text {
                  .num {
                      background: $mc-color-dark;
                      color: #FFFFFF;
                  }
                  .text {
                      color: #FFFFFF!important;
                  }
                }
            }
        }
          .buttons {
              .link-btn {
                  width: 314px;
                  height: 61px;
                  border-radius: 0;
                  background: $mc-color-dark;
                  border: none;
                  margin-top: 53px;
                  margin-bottom: 28px;
                  .p-button-label {
                      font-family: 'arialBold' ,sans-serif;
                  }
                  &:hover {
                      background: white!important;
                      .p-button-label {
                          color: $mc-color-dark;
                      }
                  }
                  &.linked {
                      width: 269px;
                  }
              }
              .create-btn {
                  background: transparent;
                  border: none;
                  padding: 0;
                  margin-left: 6px;
                  color: white;
                  font-family: 'arialBold' ,sans-serif;
              }
              .text {
                  color: white;
                  &:last-child {
                      font-size: 14px;
                  }
              }
          }
      }
      .questions {
          margin-top: 37px;
          //margin-left: 226px;
          padding-bottom: 108px;
          .title {
              font-family: 'arialBold', sans-serif;
          }
          .p-accordion {
              .p-accordion-header .p-accordion-header-link .p-accordion-header-text, .p-accordion-content {
                  font-family: 'arialRegular', sans-serif;
              }
          }
      }
      .apps {
          width: 820px;
          text-align: center;
          display: inline-block;
          padding-bottom: 112px;
      }
      .show-coupon {
          text-align: center;
          position: relative;
          .microsoft-image .image {
              background-image: url(/assets/images/mic-bg-step2-l.png);
              &.cn {
                background-image: url('/assets/images/mic-cn-step2-l.webp');
              }
              .shadow {
                  background: rgba(51, 51, 51, 0.32);
              }
              .on-top-shadow {
                  padding-left: 0!important;
                  .microsoft-coupon-image {
                      background-image: url(/assets/images/microsoft-coupon-new.png);
                      width: 725px;
                      height: 333px;
                      margin-top: -71px;
                      background-size: 100%;
                      display: inline-block;
                      background-repeat: no-repeat;
                      .confetti-cover {
                          //background-image: url('/assets/images/microsoft-confetti.png');
                          background-image: url('/assets/images/confetti-golden.gif');
                          width: 100%;
                          height: 100%;
                          //display: inline-block;
                          background-size: 100%;
                          background-repeat: no-repeat;
                          position: absolute;
                          //left: 168px;
                          //margin-left: -346px;
                          //margin-top: 26px;
                          z-index: -1;
                      }
                  }
                  .content {
                      margin-bottom: 0;
                  }
              }
          }
          .buttons {
            margin-top: 40px;
              .link-btn {
                  margin-top: 40px;
                  margin-bottom: 28px;
                  width: 267px;
              }
              .or {
                  display: none;
                  font-family: 'arialRegular' ,sans-serif;
                  color: white;
                  &.mobile {
                    display: none;
                }
                &.desktop {
                    margin: 0;
                    display: inline-block;
                    padding-left: 14px;
                    padding-right: 9px;
                    padding-top: 22px;
                    vertical-align: top;
                }
              }
              .get-coupon-btn {
                a {
                    border-color: $mc-color-dark;
                    background: $mc-color-dark;
                    width: 321px;
                    height: 61px;
                    border-radius: 0;
                    .icon-box {
                        width: 61px;
                        height: 59px;
                        border-radius: 0;
                        border: 1px solid $mc-color-dark;
                        i {
                            background-image: url('/assets/icons/google-ads.svg');
                            width: 26px;
                            height: 24px;
                            left: 16px;
                            top: 17px;
                        }
                    }
                    .text {
                        background:$mc-color-dark;
                        font-family: 'arialBold' ,sans-serif;
                        width: calc(100% - 61px);
                        line-height: 59px !important;
                        display: inline-block!important;
                    }
                    &:hover {
                        border-color: $mc-color-dark;
                        .text {
                            background: #FFFFFF;
                            color: $mc-color-dark!important;
                        }
                        .icon-box {
                            border: 1px solid transparent;
                        }
                    }
                }
            }
            .another-acc-btn {
                .link-btn {
                    margin: 0;
                    .p-button-label {
                        font-family: 'arialBold' ,sans-serif;
                        color: white;
                        margin-top: -5px;
                        text-decoration: underline;
                    }
                    &:hover {
                        background: transparent!important;
                    }
                }
            }
          }
          .apps {
              width: 820px;
              text-align: center;
              display: inline-block;
              margin-top: 33px;
              //margin-bottom: 111px;
          }
      }
      .powered {
        .powered-position {
            position: relative;
            left: 76px;
            max-width: 865px !important;
            .info {
                float: left;
                max-width: 616px;
                padding: 0;
                padding-right: 58px !important;
            }
            .badget {
                float: left;
            }
            .link-btn {
                width: 314px;
                height: 61px;
                border-radius: 0;
                background: $mc-color-dark;
                border: none;
                .p-button-label {
                    font-family: 'arialBold' ,sans-serif;
                }
                &:hover {
                    background: white!important;
                    .p-button-label {
                        color: $mc-color-dark;
                    }
                }
                &.linked {
                    width: 269px;
                }
            }
        }
      }
      &.last-step .microsoft-image{
        text-align: center;
        height: 740px;
        .buttons {
            .create-btn {
                position: relative;
                top: -49px;
                margin-left: 20px;
            }
        }
      }
      .adcore-logo {
            margin: 0;
            width: 100%;
            a {
                right: 129px;
                top: -44px;
                left: initial;
            }
      }
    }
    &.google-coupon {
      text-align: center;
      font-family: 'proSansRegular', sans-serif;
      .google-tag {
          margin-top: 20px;
          margin-bottom: 30px;
          display: block;
          i {
              background-image: url('/assets/icons/google-partner.svg');
              width: 117px;
              height: 112px;
              background-repeat: no-repeat;
              display: inline-block;
              background-size: 100%;
          }
      }
      .content {
          font-size: 48px;
          width: 650px;
          display: inline-block;

          span.bold {
                color: $gl-color;
                font-family: 'proSansBold', sans-serif;
                  &.sign {
                      font-size: 25px;
                      position: relative;
                      left: 2px;
                      top: -16px;
                      margin-top: -12px;
                  }
          }
      }
      .google-eligible-btn button {
        margin-top: 12px;
        font-family: 'proSansRegular', sans-serif;
        font-size: 16px;
        color: $gl-color;
        background: transparent;
        border: transparent;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
      }
      .link-acc {
          .num {
              background: $gl-color;
          }
          .line {
              background: $border;
          }
          .steps {
            margin-top: 35px;
          }
          .step {
              .text {
                  color: $main-text-color;
              }
              &[isactive='true'] {
                .num {
                    background: $gl-color;
                }
                .text {
                    color: $main-text-color!important;
                }
              }
              &.completed {
                .num {
                    background: #34A852;
                    &::before {
                        content: '';
                        background-image: url('/assets/icons/check-white.svg');
                        width: 10px;
                        height: 9px;
                        display: inline-block;
                        background-repeat: no-repeat;
                    }
                }
                .text {
                    color: $main-text-color!important;
                }
              }
          }
          .step[isactive='false'] {
              .num {
                  background: $diabled-text;
              }
              .text {
                  color: $diabled-text;
              }
              &.middle-text {
                .num {
                    background: $gl-color;
                }
                .text {
                    color: $main-text-color!important;
                }
              }
          }
      }
      .buttons {
            margin-top: 60px;
            height: 104px;
            .link-btn {
                width: 271px;
                height: 45px;
                border: none;
                background: $gl-color;
                border-radius: 5px;
                .p-button-label {
                    font-family: 'proSansBold', sans-serif;
                }
                &:hover {
                    background: $gl-color-dark;
                }
            }
            .create-btn {
                background: transparent;
                border: none;
                color: $gl-color;
                border-radius: 0;
                padding: 0;
                padding-bottom: 3px;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            .or {
                color: $diabled-text;
                margin-top: 12px;
                margin-bottom: 9px;
                &.mobile {
                    display: none;
                }
                &.desktop {
                    margin: 0;
                    display: inline-block;
                    padding-left: 14px;
                    padding-right: 9px;
                    padding-top: 14px;
                    vertical-align: top;
                }
            }
            .text {
                color: $diabled-text;
                margin-top: 8px;
            }
            .get-coupon-btn {
                a {
                    border-color: $gl-color;
                    background: $gl-color;
                    .icon-box {
                        i {
                            background-image: url('/assets/icons/microsoft-ads.svg');
                            width: 18px;
                            height: 18px;
                            left: 14px;
                            top: 12px;
                        }
                    }
                    .text {
                        background:$gl-color;
                        font-family: 'proSansBold', sans-serif;
                    }
                    &:hover {
                        border-color: $gl-color-dark;
                        .text {
                            background: $gl-color-dark;
                        }
                    }
                }
            }
            .another-acc-btn {
                .link-btn {
                    .p-button-label {
                        font-family: 'proSansRegular', sans-serif;
                        color: $gl-color;
                        margin-top: -5px;
                    }
                }
            }
            .additional-text {
                font-size: 14px;
                color: $diabled-text;
                margin-bottom: 25px;
                margin-top: 60px;
                line-height: 19px;
            }
      }
      .show-coupon {
        .confetti-cover {
            //background-image: url('/assets/images/google-confetti.png');
            background-image: url('/assets/images/confetti-golden.gif');
            width: 100%;
            height: 100%;
            //display: inline-block;
            background-size: 100%;
            background-repeat: no-repeat;
            position: absolute;
            z-index: -1;
            //left: 168px;
            //margin-left: -346px;
            //margin-top: 26px;
            top: -63px;
        }
          .image {
              background-image: url('/assets/images/google-coupon.png');
              width: 720px;
              height: 400px;
              display: inline-block;
              background-size: 100%;
              margin-top: -96px;
              margin-bottom: -35px;
              position: relative;
              z-index: -1;

          }
          .buttons {
              margin-top: 42px;
          }
          .apps {
              width: 820px;
              text-align: center;
              display: inline-block;
          }
      }
      .questions {
          margin-top: 37px;
          .title {
              font-family: 'proSansBold', sans-serif;
          }
          .p-accordion {
              .p-accordion-header .p-accordion-header-link .p-accordion-header-text, .p-accordion-content {
                  font-family: 'proSansRegular', sans-serif;
              }
          }
      }
      .footer {
          margin-top: 60px;
      }
      .apps-main-title {
        margin-top: -16px;
        margin-bottom: 28px;
      }
    }
    &.yt-layout-addition {
        .link-acc .step[isactive=true] .num {
            background: $yt-color;
        }
        .google-eligible-btn button ,.buttons .create-btn {
            color: #000000;
        }
        &.step2 {
            .step1 .num {
                background: $yt-color!important;
                &::before {
                    content: '';
                    background-image: url('/assets/icons/check-white.svg');
                    width: 10px;
                    height: 10px;
                    display: inline-block;
                    background-size: 100%;
                    background-repeat: no-repeat;
                }
            }
        }
        .youtube-tag {
            margin-bottom: 30px;
            display: block;
            i {
                background-image: url('/assets/icons/youtube-full-logo.svg');
                width: 185px;
                height: 41px;
                background-repeat: no-repeat;
                display: inline-block;
                background-size: 100%;
                margin-top: 50px;
                margin-bottom: 10px;
            }
        }
        .content span.bold {
            color: $yt-color;
        }
        .link-acc .num {
            background: $yt-color;
        }
        .buttons .link-btn {
            background: $yt-color;
            width: 331px;
            max-width: 331px;
            text-align: center;
            &:hover {
                background: $yt-color-dark!important;
            }
        }
    }
    &.xndr-layout-addition {
        .link-acc .step[isactive=true] .num {
            background: $xndr-color;
        }
        .link-acc  .step[isactive=false].middle-text .num {
            background: $xndr-color;
        }
        .google-eligible-btn button ,.buttons .create-btn {
            color: #000000;
        }
        .xndr-tag {
            margin-bottom: 30px;
            display: block;
            i {
                background-repeat: no-repeat;
                display: inline-block;
                background-size: 100%;
                margin-top: 50px;
                margin-bottom: 10px;
                &:first-child {
                    background-image: url('/assets/icons/xandr-full-logo.svg');
                    width: 105px;
                    height: 27px;
                }
            }
        }
        .content span.bold {
            color: $xndr-color;
        }
        .link-acc .num {
            background: $xndr-color;
        }
        .buttons .link-btn {
            background: $xndr-color!important;
            width: 331px;
            max-width: 331px;
            text-align: center;
            &:hover {
                background: $xndr-color-dark!important;
            }
        }
        .acc-loader-blue {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0) 0 70%, #FC5047 0) 50%/8% 100%, linear-gradient(90deg, rgba(0, 0, 0, 0.25) 30%, rgba(0, 0, 0, 0) 0 70%, #FC5047 0) 50%/100% 8%;
            background-repeat: no-repeat;
        }
    }
    &.yh-layout-addition {
        .link-acc .step[isactive=true] .num {
            background: $yh-color;
        }
        .link-acc  .step[isactive=false].middle-text .num {
            background: $yh-color;
        }
        .google-eligible-btn button ,.buttons .create-btn {
            color: #000000;
        }
        .yh-tag {
            margin-bottom: 30px;
            display: block;
            i {
                background-repeat: no-repeat;
                display: inline-block;
                background-size: 100%;
                margin-top: 50px;
                margin-bottom: 10px;
                &:first-child {
                    background-image: url('/assets/icons/yahoo-logo.svg');
                    width: 105px;
                    height: 44px;
                }
            }
        }
        .content span.bold {
            color: $yh-color;
        }
        .link-acc .num {
            background: $yh-color;
        }
        .buttons .link-btn {
            background: $yh-color!important;
            width: 331px;
            max-width: 331px;
            text-align: center;
            &:hover {
                background: $yh-color-dark!important;
            }
        }
        .acc-loader-blue {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0) 0 70%, #39017C 0) 50%/8% 100%, linear-gradient(90deg, rgba(0, 0, 0, 0.25) 30%, rgba(0, 0, 0, 0) 0 70%, #39017C 0) 50%/100% 8%;
            background-repeat: no-repeat;
        }
    }
    &.n-layout-addition {
        .link-acc .step[isactive=true] .num {
            background: $n-color;
        }
        .link-acc  .step[isactive=false].middle-text .num {
            background: $n-color;
        }
        .google-eligible-btn button ,.buttons .create-btn {
            color: #000000;
        }
        .n-tag {
            margin-bottom: 30px;
            display: block;
            i {
                background-repeat: no-repeat;
                display: inline-block;
                background-size: 100%;
                margin-top: 50px;
                margin-bottom: 10px;
                &:first-child {
                    background-image: url('/assets/icons/netflix-logo.svg');
                    width: 96px;
                    height: 26px;
                    position: relative;
                    top: 8px;
                }
            }
        }
        .content span.bold {
            color: $n-color;
        }
        .link-acc .num {
            background: $n-color;
        }
        .buttons .link-btn {
            background: $n-color!important;
            width: 331px;
            max-width: 331px;
            text-align: center;
            &:hover {
                background: $n-color-dark!important;
            }
        }
        .acc-loader-blue {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0) 0 70%, #D81F26 0) 50%/8% 100%, linear-gradient(90deg, rgba(0, 0, 0, 0.25) 30%, rgba(0, 0, 0, 0) 0 70%, #D81F26 0) 50%/100% 8%;
            background-repeat: no-repeat;
        }
    }
    &.in-layout-addition {
        .link-acc .step[isactive=true] .num {
            background: $in-color;
        }
        .link-acc  .step[isactive=false].middle-text .num {
            background: $in-color;
        }
        .google-eligible-btn button ,.buttons .create-btn {
            color: #000000;
        }
        .in-tag {
            margin-bottom: 30px;
            display: block;
            i {
                background-repeat: no-repeat;
                display: inline-block;
                background-size: 100%;
                margin-top: 50px;
                margin-bottom: 10px;
                &:first-child {
                    background-image: url('/assets/icons/linkedIn-logo.svg');
                    width: 105px;
                    height: 28px;
                    position: relative;
                    top: 7px;
                }
            }
        }
        .content span.bold {
            color: $in-color;
        }
        .link-acc .num {
            background: $in-color;
        }
        .buttons .link-btn {
            background: $in-color!important;
            width: 331px;
            max-width: 331px;
            text-align: center;
            &:hover {
                background: $in-color-dark!important;
            }
        }
        .acc-loader-blue {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0) 0 70%, #005480 0) 50%/8% 100%, linear-gradient(90deg, rgba(0, 0, 0, 0.25) 30%, rgba(0, 0, 0, 0) 0 70%, #005480 0) 50%/100% 8%;
            background-repeat: no-repeat;
          }
    }
    &.xndr-layout-addition, &.yh-layout-addition, &.n-layout-addition, &.in-layout-addition {
        .buttons {
            height: 165px;
            .text {
                margin-top: 14px;
                margin-bottom: 7px;
            }
            .create-btn {
                cursor: pointer;
            }
            &.loader-on {
                height: 210px;
                .additional-text {
                    margin-top: 0!important;
                }
                .loader-box {
                    top: -21px!important;
                }
            }
        }
        .powered {
            display: flow-root;
            .powered-position {
                position: relative;
                left: 76px;
                max-width: 865px !important;
                .info {
                    float: left;
                    max-width: 616px;
                    padding: 0;
                    padding-right: 58px !important;
                }
                .badget {
                    float: left;
                }
                .link-btn {
                    width: 289px;
                    height: 45px;
                    border-radius: 5px;
                    background: $mc-color-dark;
                    border: none;
                    .p-button-label {
                        font-family: 'arialBold' ,sans-serif;
                    }
                    &.linked {
                        width: 269px;
                    }
                }
            }
          }
    }
    &.fixed-header {
        height: 100%;
        .header {
            height: 118px;
            .logo {
                padding-right: 25px;
                margin-right: 10px;
                border-right: 1px solid #DEDEDE;
                padding-top: 5px;
                i {
                    background-size: 100%;
                    display: inline-block;
                }
                .logo-box {
                    vertical-align: top;
                    width: 41px;
                    height: 40px;
                    background-image: url('/assets/images/couponer.png');
                    border-radius: 5px;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    text-align: center;
                    padding-top: 6px;
                    margin-right: 10px;
                }
                .logo-text {
                    padding-top: 6px;
                    .text1 {
                        font-size: 19px;
                    }
                    .text2 {
                        font-size: 11px;
                        margin: 0 6px;
                        position: relative;
                        top: -2px;
                    }
                }
                i {
                    background-image: url('/assets/icons/adcore-logo.svg');
                    width: 36px;
                    height: 9px;
                    background-size: contain;
                    position: relative;
                    top: -1px;
                }
            }
            .col.h-full:not(.logo):not(.coupon-menu) {
                flex-basis: auto;
                width: calc(100% - 360px);
                &.text-right {
                    width: 360px;
                    padding-top: 40px;
                    padding-right: 19px;
                }
                &.text-left {
                    padding-left: 50px;
                    padding-top: 33px;
                }
            }
            .coupon-menu {
                width: 200px;
                position: relative;
                max-width: 320px;
                width: 100%;
                min-width: 200px;
                padding: 0;
                border-radius: 5px 5px 0px 0px;
                padding-top: 8px;
                .p-dropdown {
                    width: 320px;
                    height: 40px;
                    outline: none;
                    box-shadow: none;
                    border-color: transparent;
                    i.arrow {
                        background-image: url('/assets/icons/arrow-black.svg');
                        width: 5px;
                        height: 9px;
                        display: inline-block;
                        background-repeat: no-repeat;
                        background-size: 100%;
                        transform: rotate(90deg);
                        margin-left: 9px;
                    }
                    &.opend {
                        border: 1px solid #DEDEDE;
                        border-bottom: transparent;
                        border-radius: 5px 5px 0px 0px;
                        i.arrow {
                            transform: rotate(270deg);
                        }
                    }
                    .p-dropdown-clear-icon, .p-dropdown-trigger {
                        display: none;
                    }
                    .p-inputtext {
                        line-height: 37px;
                        padding: 0 18px;
                    }
                    .coupon-menu-panel-style {
                        padding-top: 16px;
                        padding-bottom: 5px;
                        border: 1px solid #DEDEDE;
                        border-radius: 0px 0px 5px 5px;
                        left: -1px;
                        margin-top: -1px;
                        position: relative;
                        .p-dropdown-items-wrapper {
                            max-height: 300px!important;
                        }
                        li {
                            background: transparent;
                            width: 33%;
                            height: auto;
                            display: inline-block;
                            text-align: center;
                            color: #000000;
                            margin-bottom: 8px;
                            &.p-highlight, &:not(.p-highlight):not(.p-disabled):hover {
                                .home-palette {
                                    background: rgb(255 170 52 / 10%);;
                                }
                                .gl-palette {
                                    background: #ECF4FC;
                                }
                                .mc-palette {
                                    background: #F1FBF9;
                                }
                                .rtb-palette {
                                    background: $light-bg;
                                 }
                                .dv-palette {
                                    background: rgb(234 246 237 / 50%);;
                                 }
                                .y-palette {
                                    background: rgb(255 229 229 / 50%);;
                                 }
                                .cr-palette {
                                    background: rgb(255 237 229 / 50%);;
                                }
                                .mcard-palette {
                                    background: #ECF4FC;
                                }
                                .yt-palette {
                                    background: #FFF0F0;
                                 }
                                 .xndr-palette {
                                    background: $light-bg;
                                 }
                                 .n-palette {
                                    background: #FFF0F0;
                                 }
                                 .in-palette {
                                    background: #F4FAFF;
                                 }
                                 .yh-palette {
                                    background: #F9F4FF;
                                 }
                            }
                            .coupon-selector-item {
                                text-align: center;
                                border-radius: 5px;
                                padding-top: 8px;
                                padding-bottom: 5px;
                                font-size: 13px;
                                white-space: initial !important;
                                overflow: initial !important;
                                word-wrap: break-word;
                                overflow-wrap: break-word;
                                display: inline-block;
                                width: 65px;
                                div {
                                    line-height: 16px;
                                }
                                i {
                                    background-size: 100%;
                                    display: inline-block;
                                    width: 14px;
                                    height: 14px;
                                    margin-bottom: 5px;
                                    background-repeat: no-repeat;
                                }
                                &.home-palette {
                                    i {
                                        width: 19px;
                                        height: 19px;
                                        background-image: url('/assets/images/couponer.png');
                                    }
                                }
                                &.gl-palette {
                                    width: 57px;
                                    i {
                                        width: 24px;
                                        height: 21px;
                                        background-image: url('/assets/icons/google-ads.svg');
                                    }
                                }
                                &.mc-palette {
                                    width: 75px;
                                    i {
                                        width: 18px;
                                        height: 18px;
                                        background-image: url('/assets/icons/microsoft-ads.svg');
                                    }
                                }
                                &.rtb-palette {
                                    i {
                                        width: 20px;
                                        height: 14px;
                                        background-image: url('/assets/images/rtb.png');
                                    }
                                 }
                                &.dv-palette {
                                    i {
                                        width: 16px;
                                        height: 18px;
                                        background-image: url('/assets/images/dv.png');
                                    }
                                 }
                                &.y-palette {
                                    i {
                                        width: 18px;
                                        height: 18px;
                                        background-image: url('/assets/images/yandex.png');
                                    }
                                 }
                                &.cr-palette {
                                    i {
                                        width: 15px;
                                        height: 20px;
                                        background-image: url('/assets/images/criteo.png');
                                    }
                                }

                                &.mcard-palette {
                                    width: 80px;
                                    i {
                                        width: 29px;
                                        height: 18px;
                                        background-image: url('/assets/images/mastercard.png');
                                    }
                                }
                                &.yt-palette {
                                    i {
                                        width: 22px;
                                        height: 15px;
                                        background-image: url('/assets/images/youtube.png');
                                    }
                                 }
                                 &.xndr-palette {
                                     i {
                                        background-image: url('/assets/icons/xandr-logo.svg');
                                        width: 22px;
                                        height: 21px;
                                     }
                                 }
                                 &.n-palette {
                                    i {
                                        width: 11px;
                                        height: 19px;
                                        background-image: url('/assets/icons/netflix.svg');
                                    }
                                 }
                                 &.in-palette {
                                    i {
                                        width: 18px;
                                        height: 18px;
                                        background-image: url('/assets/icons/linkedIn.svg');
                                    }
                                 }
                                 &.yh-palette {
                                    i {
                                        width: 20px;
                                        height: 18px;
                                        background-image: url('/assets/icons/yahoo.svg');
                                    }
                                 }
                            }
                            &:last-child {
                                //for yahoo align
                                vertical-align: top;
                            }
                        }
                    }
                }

            }
            .links {
                p-button[selected='true'] {
                    .selected-line {
                        background: transparent linear-gradient(88deg, #FFAD20 0%, #FF97B3 100%) 0% 0% no-repeat padding-box;
                    }
                    .p-button-label {
                        font-family: 'homePageBOLD';
                    }
                }

                .p-button {
                    margin-right: 28px;
                    cursor: pointer;
                    color: #000000;
                    display: inline-block;
                    background: transparent !important;
                    border: none;
                    position: relative;
                    height: 43px;
                    div {
                        position: absolute;
                        bottom: 5px;
                        height: 3px;
                        width: 100%;
                        background: transparent;
                        margin-top: 5px;
                    }
                }
            }
        }
        .main-content {
            height: calc(100% - 118px);
            overflow-y: auto;
            overflow-x: hidden;
            text-align: center;
            .main-logo {
                display: inline-block;
                background-repeat: no-repeat;
                background-size: contain;
                margin-top: 30px;
                margin-bottom: 30px;
            }
            .logo-via-mic {
                margin-top: 50px;
                text-decoration: none;
                span {
                    padding: 0 15px;
                    position: relative;
                    top: 12px;
                    color: #000000;
                }
                i {
                    margin-top: 0;
                    vertical-align: top;
                    &:last-child {
                        background-image: url('/assets/icons/mc-logo.svg')!important;
                        width: 128px;
                        height: 44px;
                    }
                }
            }
            .coupon-layout-home-body {
                max-width: 985px;
                margin: 0 auto;
                .coupon-box-large-layout {
                    margin: 27px 0;
                }
                .big-screen-layout {
                    width: 983px;
                    position: relative;
                    .popular-c {
                        width: 427px;
                        display: inline-block;
                        height: 218px;
                        //background: saddlebrown;
                        vertical-align: bottom;
                        margin-left: 33px;
                    }
                    .carusel-position {
                        width: 504px;
                        text-align: right;
                        display: inline-block;
                        .p-carousel-items-content {
                            width: 438px;
                            margin-left: 34px;
                        }
                        .p-carousel-prev {
                            position: absolute;
                            left: -14px;
                        }
                        .p-carousel-next {
                            position: absolute;
                            right: -16px;
                        }
                    }
                }
            }
            .powered.home-layout {
                margin: 40px 0;
            }
            .questions {
                margin-bottom: 60px;
            }
        }
    }
  }

  .powered-colored-layout .powered {
    .info {
        font-family: 'homePageBOOK';
        .title {
            font-family: 'homePageBold'!important;
        }
        .p-button-label {
            font-family: 'homePageBold';
        }
    }
  }

  .popular-coupon {
    display: inline-block;
    text-align: center;
    h3 {
        margin-bottom: 22px!important;
    }
    margin-bottom: 60px;
  }

  //tags & p-chips

    .tags {
    border: 1px solid;
    width: 100%;
    border-radius: 5px;
    border: 1px solid $border;
    .p-chips-multiple-container {
        //gap: 0;
    }
    li {
        margin-right: 6px;
    }
    input {
      border: none !important;
      padding: 0 !important;
      width: 97px !important;
    }

    .p-autocomplete-multiple-container {
      height: auto;
      min-height: 42px;
      padding: 1px 14px;
      border: 1px solid $border!important;

      &.p-focus {
        box-shadow: none !important;
        border: 1px solid $main-text-color!important;
      }

      .p-autocomplete-input-token {
        padding: 0;
        width: 100%;

        input {
          font-family: 'appFont', sans-serif !important;
          font-size: 13px !important;
        }
      }

      .p-autocomplete-token {
        //background: $bg-light-nude !important;
        padding: 0 5px;
        height: 26px;
        line-height: 25px;
        margin: 6px 6px 2px 2px !important;
        border-radius: 4px;
      }
    }

  }

    .p-chips .p-chips-multiple-container {
    width: 258px !important;
    gap: 5px;
    padding: 5px 15px !important;
    .p-chips-token {
        border-radius: 4px;
        margin-right: 0px;
        padding: 5px 7px;
    }
    .p-chips-input-token {
        padding: 0;
    }
    input {
        height: 28px !important;
    }
    .p-chips-token-icon {
        height: 13px;
        margin-left: 6px;
    }
}


  // Buttons

    .p-button:focus {
        box-shadow: none!important;
    }

    .p-button.bold {
        .p-button-label {
            font-weight: bold!important;
        }
    }

    .google-ad-text {
    max-width: 581px;
    margin: 0 auto;
    margin-bottom: -15px;
    margin-top: 16px;
    width: 500px;
    line-height: 21px;
    .mobile-label {
        display: none;
    }
    .desktop-label {
        display: inline;
    }
    a {
        color: $gl-color;
        text-decoration: none;
        padding-bottom: 1px;
        &:hover {
            text-decoration: underline;
        }
    }
    .space {
        display: none;
    }
    }
    .buttons {
    .get-coupon-btn {
        display: inline-block;
        a {
            display: inline-block;
            width: 318px;
            height: 45px;
            border-radius: 5px;
            border: 1px solid;
            cursor: pointer;
            .icon-box {
                width: 45px;
                height: 43px;
                display: inline-block;
                background: white;
                border-radius: 4px 0px 0 4px;
                position: relative;
                i {
                    display: inline-block;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    position: absolute;
                }
            }
            .text {
                display: inline-block;
                height: 100%;
                width: calc(100% - 45px);
                margin-top: 0!important;
                vertical-align: top;
                color: white!important;
                text-align: center;
                line-height: 43px!important;
                font-size: 16px!important;
            }
        }
    }
    .another-acc-btn {
        display: inline-block;
        .link-btn {
            background: transparent!important;
            width: auto!important;
            max-width: initial!important;
            padding: 0;
            &:hover {
                .p-button-label {
                    text-decoration: underline;
                }
            }
        }
    }
    }
    .primary-btn.select-coupon {
    padding: 0 22px;
    height: 45px;
    background: #FFAD20;
    border: transparent;
    .p-button-label {
        color: #000000;
        font-family: 'homePageBOLD';
    }
    &:hover {
        opacity: 0.8;
    }
    }
    .secondary-btn.select-coupon {
        background: transparent!important;
        border: none!important;
        height: 44px;
        padding-left: 10px;
        padding-right: 0;
        margin: 0!important;
        .p-button-label {
            font-size: 16px;
            font-family: 'proSansRegular', sans-serif;
            color: $gl-color;
            border-bottom: 1px solid transparent;
            color: #000000;
        }
        &:hover {
            .p-button-label {
                border-bottom: 1px solid #000000;
            }
        }
        &.continue {
            padding-left: 16px;
        }
    }

    .link-btn.outline {
        width: 193px;
        height: 45px;
        border: 2px solid #000000;
        border-radius: 5px;
        background: transparent;
        &.colored-btn {
            border: 2px solid transparent;
            background: #FFAD20;
        }
        span {
            color: #000000;
        }
        &:hover {
            opacity: 0.7;
        }
    }

    //check box

    .p-checkbox {
        .p-checkbox-box {
            width: 16px;
            height: 16px;
            border: 1px solid #D1CAC6;
            box-shadow: none!important;
            &.p-highlight, &:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
                border-color: #000000!important;
                background: #000000!important;
                checkicon {
                  &::before {
                    content: '';
                    background-image: url('/assets/icons/check-white.svg');
                    width: 10px;
                    height: 10px;
                    display: inline-block;
                    background-size: 100%;
                    background-repeat: no-repeat;
                  }
                  .p-icon {
                    display: none;
                  }
                }
              }
              &.p-focus {
                box-shadow: none!important;
              }
        }
    }
    .p-checkbox-label {
        cursor: pointer;
        margin-bottom: 3px;
        display: inline-block;
        margin-left: 6px;
    }

    //  p-dropdown

    .p-dropdown {
        box-shadow: none!important;
    }

    //titles

    .explore-title {
        margin-bottom: 24px;
        //margin-top: 30px;
    }

  //tooltip

  .tooltip {
    background-image: url('/assets/icons/tooltip.svg');
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: 100%;
    cursor: pointer;
  }

    .p-tooltip {
        border-radius: 5px;
        background: #2D2D2D;
        padding: 14px 17px!important;
        .p-tooltip-text {
            font-size: 13px;
            color: #FFFFFF;
            font-family: 'homePageBOOK';
            padding: 0!important;
            line-height: 18px;
        }
        .p-tooltip-arrow {
            display: none;
        }
    }

  // toast message

  .p-toast  {
    width: auto!important;
    .p-toast-message {
        width: 356px;
        height: 487px;
        min-height: 429px;
        max-height: 550px;
        height: auto;
        color: $main-text-color!important;
        background: white!important;
        border: none !important;
        border-radius: 5px!important;
        margin: 0!important;
        .p-toast-message-content {
            font-family: 'proSansregular', sans-serif;
            padding: 49px!important;
            padding-top: 42px!important;
              text-align: left;
              display: block;
            }

        .p-toast-icon-close {
            top: -19px;
            right: -12px;

          .p-toast-icon-close-icon::before {
            content: '';
            background-image: url('/assets/icons/message-close.svg');
            display: inline-block;
            width: 14px;
            height: 14px;
            background-size: 100%;
          }

          &:hover, &:focus {
            background: transparent !important;
            box-shadow: none !important;
          }
        }

        .p-toast-icon-close {
            top: 13px;
            position: absolute;
            right: 9px;
        }
    }
  }

  //player video

  .video-player {
    display: inline-block;
    margin-bottom: 62px;
    width: 715px;
    height: 402px;
    .aligns {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .img {
        background-image: url('/assets/images/player-bg.png');
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        background-size: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
    }
    iframe {
        //width: 100%;
        border: 0;
    }
  }

  //video gif position

  .video-mobile-view {
    display: none;
    height: 271px;
    width: 441px;
    margin: 0 auto;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
}
 .video-desk-view {
    display: block;
    .video-dialog {
        margin-right: 15px!important;
    }
 }

 //dialogs

 .create-acc-dialog.open-recaptcha {
    .p-dialog-header .p-dialog-title {
        font-size: 14px !important;
    }
    .p-dialog-content {
        margin-top: 0;
        max-height: 140px;
    }
 }

 .create-acc-dialog {
    width: 356px!important;
    height: auto;
    min-height: 220px;
    border-radius: 5px!important;
    border: none!important;
    box-shadow: 0px 1px 6px #00000026 !important;
    .p-dialog-header {
        border-top-right-radius: 5px!important;
        border-top-left-radius: 5px!important;
        border-bottom:none!important;
        padding: 31px 48px 10px 48px!important;
        font-family: 'homePageBold', sans-serif;
        font-size: 20px!important;
        font-weight: normal!important;
        text-align: left;
        color: $main-text-color;
        display: block;
        .p-dialog-title {
            font-family: 'homePageBold', sans-serif;
            font-size: 20px!important;
            font-weight: normal!important;
            color: $main-text-color;
        }
        .p-dialog-header-icon {
            box-shadow: none!important;
            position: absolute;
            top: 10px;
            right: 7px;
           .p-dialog-header-close-icon {
            &::before {
                content: '';
                background-image: url('/assets/icons/message-close.svg');
                display: inline-block;
                width: 14px;
                height: 14px;
                background-size: 100%;
            }
            }
        }
        .success-icon {
            background-image: url('/assets/icons/success-icon.svg');
            width: 19px;
            height: 19px;
            display: inline-block;
            background-size: 100%;
            background-repeat: no-repeat;
            position: relative;
            left: 5px;
            top: 3px;
        }
    }
    .p-dialog-content {
        font-size: 13px;
        padding: 0 48px 30px 48px!important;
        font-family: 'homePageBOOK', sans-serif;
        font-weight: normal!important;
        margin-top: -9px;
        overflow: hidden;
        .field {
            margin-bottom: 10px;
        }
        h5 {
            margin-bottom: 8px;
            font-family: "homePageBOld", sans-serif;
            font-size: 11px;
            font-weight: normal;
        }
        h4 {
            font-weight: normal!important;
            padding-top: 11px;
            padding-bottom: 18px;
            line-height: 19px;
        }
        .middle-input .p-dropdown {
            //margin: 12px 0;
        }
        .p-chips-multiple-container {
            box-shadow: none;
            border: none;
        }
        input, .p-dropdown {
            width: 258px;
            height: 39px;
            border-radius: 5px;
            border: 1px solid $border;
            box-shadow: none!important;
            .pi.pi-chevron-down {
                &::before {
                    font-size: 11px;
                    color: $main-text-color;
                }
            }
            .p-dropdown-label, &:not(.p-dropdown) {
                font-size: 13px;
                font-family: 'homePageBOOK', sans-serif;
                color: $main-text-color;
                padding-left: 15px;
                &.p-placeholder, &::placeholder {
                    color: $diabled-text!important;
                }
            }
            .p-dropdown-label {
                padding-top: 12px;
            }
            &:enabled:focus,&:not(p-disabled).p-focus {
                box-shadow: none;
                border-color: $main-text-color;
            }
            &.p-dropdown-open {
                border-radius: 5px 5px 0px 0px;
            }
        }
        .buttons {
            padding-top: 22px;
        }
        p {
            font-size: 13px;
            font-family: 'homePageBOOK', sans-serif;
            line-height: 19px;
            text-align: left;
            margin-bottom: -7px;
            margin-top: 11px;
        }
    }
    .p-dialog-footer {
        border-top: none!important;
        text-align: left!important;
        padding: 0px 41px 30px 48px !important;
    }
    .primary-btn {
        border-radius: 5px;
        height: 45px;
        background: $gl-color;
        width: 185px!important;
        border: none;
        margin: 0!important;
        .p-button-label {
            font-size: 16px;
            font-family: 'proSansBold', sans-serif;
        }
        &:hover {
            background: $gl-color-dark;
        }
    }
    .secondary-btn {
        background: transparent!important;
        border: none!important;
        height: 44px;
        padding-left: 20px;
        padding-right: 0;
        margin: 0!important;
        .p-button-label {
            font-size: 16px;
            font-family: 'proSansRegular', sans-serif;
            color: $gl-color;
            border-bottom: 1px solid transparent;
        }
        &:hover {
            .p-button-label {
                border-bottom: 1px solid $gl-color;
            }
        }
        &.continue {
            padding-left: 16px;
        }
    }
 }

 .verify-code-dialog {
  width: 356px!important;
  height: auto;
  min-height: 220px;
  border-radius: 5px!important;
  border: none!important;
  box-shadow: 0 1px 6px #00000026 !important;
  .p-dialog-header {
    border-top-right-radius: 5px!important;
    border-top-left-radius: 5px!important;
    border-bottom:none!important;
    padding: 15px 48px 20px 48px!important;
    font-family: 'homePageBold', sans-serif;
    font-size: 20px!important;
    font-weight: normal!important;
    text-align: left;
    color: $main-text-color;
    display: block;
    .p-dialog-title {
      font-family: 'homePageBold', sans-serif;
      font-size: 20px!important;
      font-weight: normal!important;
      color: $main-text-color;
    }
    .p-dialog-header-icon {
      box-shadow: none!important;
      position: absolute;
      top: 10px;
      right: 7px;
      .p-dialog-header-close-icon {
        &::before {
          content: '';
          background-image: url('/assets/icons/message-close.svg');
          display: inline-block;
          width: 14px;
          height: 14px;
          background-size: 100%;
        }
      }
    }
    .success-icon {
      background-image: url('/assets/icons/success-icon.svg');
      width: 19px;
      height: 19px;
      display: inline-block;
      background-size: 100%;
      background-repeat: no-repeat;
      position: relative;
      left: 5px;
      top: 3px;
    }
  }
  .p-dialog-content {
    font-size: 13px;
    padding: 0 48px 30px 48px!important;
    font-family: 'homePageBOOK', sans-serif;
    font-weight: normal!important;
    overflow: hidden;
    .field {
      margin-bottom: 10px;
    }
    h5 {
      margin-bottom: 8px;
      font-family: "homePageBOld", sans-serif;
      font-size: 11px;
      font-weight: normal;
    }
    h4 {
      font-weight: normal!important;
      padding-top: 11px;
      padding-bottom: 18px;
      line-height: 19px;
    }
    .middle-input .p-dropdown {
      //margin: 12px 0;
    }
    .p-chips-multiple-container {
      box-shadow: none;
      border: none;
    }
    input, .p-dropdown {
      width: 258px;
      height: 39px;
      border-radius: 5px;
      border: 1px solid $border;
      box-shadow: none!important;
      .pi.pi-chevron-down {
        &::before {
          font-size: 11px;
          color: $main-text-color;
        }
      }
      .p-dropdown-label, &:not(.p-dropdown) {
        font-size: 13px;
        font-family: 'homePageBOOK', sans-serif;
        color: $main-text-color;
        padding-left: 15px;
        &.p-placeholder, &::placeholder {
          color: $diabled-text!important;
        }
      }
      .p-dropdown-label {
        padding-top: 12px;
      }
      &:enabled:focus,&:not(p-disabled).p-focus {
        box-shadow: none;
        border-color: $main-text-color;
      }
      &.p-dropdown-open {
        border-radius: 5px 5px 0px 0px;
      }
    }
    .buttons {
      padding-top: 22px;
    }
    p {
      font-size: 13px;
      font-family: 'homePageBOOK', sans-serif;
      line-height: 19px;
      text-align: left;
      margin-bottom: -7px;
      margin-top: 11px;
    }
  }
  .p-dialog-footer {
    border-top: none!important;
    text-align: left!important;
    padding: 0px 41px 30px 48px !important;
  }
  .primary-btn {
    border-radius: 5px;
    height: 45px;
    background: $gl-color;
    width: 185px!important;
    border: none;
    margin: 0!important;
    .p-button-label {
      font-size: 16px;
      font-family: 'proSansBold', sans-serif;
    }
    &:hover {
      background: $gl-color-dark;
    }
  }
  .secondary-btn {
    background: transparent!important;
    border: none!important;
    height: 44px;
    padding-left: 20px;
    padding-right: 0;
    margin: 0!important;
    .p-button-label {
      font-size: 16px;
      font-family: 'proSansRegular', sans-serif;
      color: $gl-color;
      border-bottom: 1px solid transparent;
    }
    &:hover {
      .p-button-label {
        border-bottom: 1px solid $gl-color;
      }
    }
    &.continue {
      padding-left: 16px;
    }
  }
}

 .create-acc-panel {
    width: 258px;
    border-top: none!important;
    border-radius: 0px 0px 5px 5px!important;
    margin-left: -1px;
    margin-top: -1px;
    .p-dropdown-header {
        height: 46px;
        border-top-right-radius: 0!important;
        border-top-left-radius: 0!important;
        background: $footer-top-bg!important;
        padding: 6px 15px!important;
        input {
            font-size: 13px;
            font-family: 'homePageBOOK', sans-serif;
            color: $main-text-color;
            padding-left: 15px;
            &.p-placeholder, &::placeholder {
                color: $diabled-text!important;
            }
        }
        .p-dropdown-filter {
            border: 1px solid $border!important;
            border-radius: 5px;
            height: 33px;
            box-shadow: none!important;
            padding-top: 1px;
            padding-right: 36px!important;
            width: 228px;
        }
        .p-dropdown-filter-icon {
            //right: 15px!important;
            margin-top: -16px;
            &::before {
                content: '';
                background-image: url('/assets/icons/search.svg');
                width: 13px;
                height: 13px;
                display: inline-block;
                background-repeat: no-repeat;
            }
        }
    }
    .p-dropdown-items-wrapper {
        max-height: 88px!important;
        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $gl-color;
            outline: none;
            height: 29px;
            border-radius: 5px;
        }
        .p-dropdown-item {
            height: 31px;
            font-size: 13px;
            color: $main-text-color!important;
            font-family: 'homePageBOOK', sans-serif;
            padding: 0 15px!important;
            line-height: 31px;
            &:hover, &.p-highlight, &:not(.p-highlight):not(.p-disabled).p-focus {
                background: #EBF4FF!important;
            }
        }
    }
    .p-dropdown-empty-message {
        font-size: 13px;
        padding-left: 15!important;
        font-family: 'homePageBOOK', sans-serif;
    }
    &.w-12 {
            margin-top: 0px;
            .p-dropdown-items-wrapper {
                &::-webkit-scrollbar-thumb {
                    background-color: #000000;
                }
            }
            &.cr-palette {
                .p-dropdown-items-wrapper {
                    .p-dropdown-item:hover, .p-dropdown-item.p-highlight {
                        background-color: rgba(255, 237, 229, 0.5)!important;
                    }
                }
            }
            &.rtb-palette {
                .p-dropdown-items-wrapper {
                    .p-dropdown-item:hover, .p-dropdown-item.p-highlight {
                        background-color: $light-bg!important;
                    }
                }
            }
            &.y-palette {
                .p-dropdown-items-wrapper {
                    .p-dropdown-item:hover, .p-dropdown-item.p-highlight {
                        background-color: rgba(255, 229, 229, 0.5)!important;
                    }
                }
            }
            &.dv-palette {
                .p-dropdown-items-wrapper {
                    .p-dropdown-item:hover, .p-dropdown-item.p-highlight {
                        background-color: rgba(234, 246, 237, 0.5)!important;
                    }
                }
            }
            &.mcard-palette {
                .p-dropdown-items-wrapper {
                    .p-dropdown-item:hover, .p-dropdown-item.p-highlight {
                        background-color: rgba(234, 246, 237, 0.5)!important;
                    }
                }
            }
            &.xndr-palette {
                .p-dropdown-items-wrapper {
                    .p-dropdown-item:hover, .p-dropdown-item.p-highlight {
                        background-color: $light-bg!important;
                    }
                }
            }
    }
}


.coupon-selection-dialog {
    width: 422px;
    .p-dialog-header {
        height: 46px;
        border-bottom-color: transparent;
        .p-dialog-header-icon:last-child {
            right: 10px;
            top: 5px;
        }
        .title {
            width: 100%;
            font-size: 20px;
            color: $main-text-color;
        }
        .p-dialog-header-icons {
            position: absolute;
            right: 0;
        }
    }
    .p-card-content {
        padding-top: 17px!important;
        padding-left: 18px!important;
        padding-right: 20px!important;
    }
    .content {
        padding: 0 65px;
        h2 {
            text-align: left;
            font-size: 16px;
            margin-bottom: 12px;
        }
        form {
            .col {
                width: 100%;
                &.col-6 {
                    width: calc(50% - 3px);
                    &:first-child {
                        margin-right: 6px;
                    }
                }
            }
            .field {
                margin-bottom: 8px;
                aux-phone {
                    .mb-3 {
                        margin-bottom: 0px!important;
                        .p-dropdown {
                            .p-dropdown-label {
                                font-size: 13px;
                                padding-left: 15px;
                            }
                            .p-dropdown-trigger {
                                width: 21px;
                                padding-right: 14px;
                                .p-dropdown-trigger-icon {
                                    width: 12px;
                                }
                            }
                        }
                    }
                }
            }
            input {
                border: 1px solid #D1CAC6;
                border-radius: 4px;
                height: 47px;
                box-shadow: none;
                padding-left: 15px;
                width: 100%;
                &::placeholder {
                    color: #4e4e4e80;
                    font-size: 13px;
                    font-family: 'homePageBOOK';
                }
            }
            .p-dropdown {
                width: 100px;
                height: 47px;
                margin-right: 6px;
                border: 1px solid #D1CAC6;
                &:not(.p-disabled).p-focus {
                    border: 1px solid #D1CAC6;
                }
            }
            .field.text-left {
                margin-top: 14px;
                margin-bottom: 25px;
            }
        }
    }
    .footer {
        padding: 32px 65px;
        padding-top: 0;
        .primary-btn {
            .p-button-label {
                font-family: 'homePageBOLD';
            }
        }
    }
    .p-dialog-footer {
        text-align: left;
        padding: 10px 65px 32px 65px;
        border-top-color: transparent;
    }
}

.header-mobile-menu-dialog {
    width: 244px;
    height: 100%;
    max-height: 847px;
    top: 0;
    position: absolute;
    right: 0;
    right: -2px;
    border-radius: 0;
    .p-dialog-header , .p-dialog-content {
        ul {
            background: transparent linear-gradient(15deg, rgba(255, 173, 32, 0.1490196078) 0%, rgba(255, 151, 179, 0.1490196078) 100%) 0% 0% no-repeat padding-box;
            //height: 100%;
            padding-top: 73px;
            padding-bottom: 20px;
            list-style-type: none;
        }
        .p-dialog-header-icons {
            position: absolute;
            right: 9px;
            top: 11px;

        }
    }
    .p-dialog-content {
        height: 100%;
        li {
            margin-bottom: 28px;
            width: fit-content;
            position: relative;
            margin-left: 35px;
            &:last-child {
                margin-bottom: 0;
            }
            .p-button {
                background: transparent;
                border: none;
                padding-bottom: 10px;
                .p-button-label {
                    font-size: 20px;
                    color: $main-text-color;
                    text-align: left;
                    font-family: 'homePageBOOK';
                    width: min-content;
                }
                .p-button-icon {
                    background-size: 100%;
                    width: 34px;
                    text-align: left;
                    &::before {
                        content: '';
                        background-size: 100%;
                        display: inline-block;
                        background-repeat: no-repeat;
                        margin-right: 18px;
                    }
                    &.gl::before {
                        background-image: url('/assets/images/gl-grey.png');
                        width: 24px;
                        height: 21px;
                    }
                    &.mc::before {
                        background-image: url('/assets/images/mc-grey.png');
                        width: 20px;
                        height: 20px;
                    }
                    &.rtb::before {
                        background-image: url('/assets/images/rtb-grey.png');
                        width: 20px;
                        height: 14px;
                    }
                    &.dv::before {
                        background-image: url('/assets/images/dv-grey.png');
                        width: 20px;
                        height: 22px;
                    }
                    &.yandex::before {
                        background-image: url('/assets/images/yandex-grey.png');
                        width: 22px;
                        height: 22px;
                    }
                    &.cr::before {
                        background-image: url('/assets/images/cr-grey.png');
                        width: 19px;
                        height: 25px;
                    }
                    &.mcard::before {
                        background-image: url('/assets/images/mcard-grey.png');
                        width: 24px;
                        height: 15px;
                    }
                    &.xndr::before {
                        background-image: url('/assets/icons/xndr-grey.svg');
                        width: 24px;
                        height: 21px;
                    }
                    &.yt::before{
                        background-image: url('/assets/images/yt-grey.png');
                        width: 23px;
                        height: 16px;
                    }
                    &.n::before{
                        background-image: url('/assets/icons/netflix-grey.svg');
                        width: 11px;
                        height: 19px;
                        margin-left: 6px;
                    }
                    &.in::before{
                        background-image: url('/assets/icons/linkedIn-grey.svg');
                        width: 18px;
                        height: 18px;
                        margin-left: 3px;
                    }
                    &.yh::before{
                        background-image: url('/assets/icons/yahoo-grey.svg');
                        width: 19px;
                        height: 17px;
                        margin-left: 3px;
                    }
                }
                .selected-line {
                    height: 4px;
                    width: 100%;
                    position: absolute;
                    bottom: 2px;
                    background: transparent;
                }
            }
            p-button[selected='true'] {
                .selected-line {
                    background: transparent linear-gradient(88deg, #FFAD20 0%, #FF97B3 100%) 0% 0% no-repeat padding-box;
                }
                .p-button-label {
                    font-family: 'homePageBOLD';
                }
            }
        }
    }
}
 //loader

 .loader-box {
    display: inline-block;
    /* margin-right: 15px; */
    position: relative;
    left: 21px;
    top: -21px;
    &.green {
        .loader div, .p-autocomplete-loader {
            color: $mc-color;
        }
    }
    &.blue {
        top: -57px;
        .loader div, .p-autocomplete-loader {
            color: #00A4EF;
        }
    }
    &.acc {
        left: 10px;
        //margin-top: 44px;
        //max-width: 463px;
        width: 502px;
        .acc-loader-green {
            margin: auto;
            margin-top: 20px;
        }
    }
 }
 .loader {
    display: inline-block;
    position: absolute;
    top: -25px;

    &.next-to-btn {
      top: -33px;
      left: 15px;
    }

    div {
      transform: none !important;
    }
  }

  .p-autocomplete-loader {
    margin-right: 10px;
    margin-top: 15px !important;
    top: -25px !important;
  }

  .pi-spin.p-autocomplete-loader {
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }

  .loader div,
  .loader div:before,
  .loader div:after,
  .p-autocomplete-loader,
  .p-autocomplete-loader:before,
  .p-autocomplete-loader:after {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }

  .loader div:after {
    animation-delay: 0s !important;
  }

  .loader div, .p-autocomplete-loader {
    color: red;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  .loader div:before,
  .loader div:after,
  .p-autocomplete-loader:before,
  .p-autocomplete-loader:after {
    content: '';
    position: absolute;
    top: 0;
  }

  .loader div:before,
  .p-autocomplete-loader:before {
    left: -11px;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .loader div:after,
  .p-autocomplete-loader:after {
    left: 11px;
    -webkit-animation-delay: 0.32s;
    animation-delay: 0.32s;
  }

  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }

  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }

  //ling acc loader

.acc-loader-green {
    margin: auto;
    width: 38px;
    height: 40px;
    aspect-ratio: 1;
    display: grid;
    border-radius: 50%;
    background:linear-gradient(0deg, #ffffff 30%, rgba(0, 0, 0, 0) 0 70%, #008575 0) 50%/8% 100%, linear-gradient(90deg, rgba(0, 0, 0, 0.25) 30%, rgba(0, 0, 0, 0) 0 70%, #008575 0) 50%/100% 8%;
    //background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0) 0 70%, #1a73e8 0) 50%/8% 100%, linear-gradient(90deg, rgba(0, 0, 0, 0.25) 30%, rgba(0, 0, 0, 0) 0 70%, #1a73e8 0) 50%/100% 8%;
    background-repeat: no-repeat;
    animation: l23 1s infinite steps(12);

  }
  .acc-loader-green::before,
  .acc-loader-green::after {
     content: "";
     grid-area: 1/1;
     border-radius: 50%;
     background: inherit;
     opacity: 0.915;
     transform: rotate(30deg);
  }
  .acc-loader-green::after {
     opacity: 0.83;
     transform: rotate(60deg);
  }

  .acc-loader-blue {
    margin: auto;
    width: 38px;
    height: 40px;
    aspect-ratio: 1;
    display: grid;
    border-radius: 50%;
    //background:linear-gradient(0deg, #ffffff 30%, rgba(0, 0, 0, 0) 0 70%, #008575 0) 50%/8% 100%, linear-gradient(90deg, rgba(0, 0, 0, 0.25) 30%, rgba(0, 0, 0, 0) 0 70%, #008575 0) 50%/100% 8%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0) 0 70%, #1a73e8 0) 50%/8% 100%, linear-gradient(90deg, rgba(0, 0, 0, 0.25) 30%, rgba(0, 0, 0, 0) 0 70%, #1a73e8 0) 50%/100% 8%;
    background-repeat: no-repeat;
    animation: l23 1s infinite steps(12);

  }
  .acc-loader-blue::before,
  .acc-loader-blue::after {
     content: "";
     grid-area: 1/1;
     border-radius: 50%;
     background: inherit;
     opacity: 0.915;
     transform: rotate(30deg);
  }
  .acc-loader-blue::after {
     opacity: 0.83;
     transform: rotate(60deg);
  }
  @keyframes l23 {
    100% {transform: rotate(1turn)}
  }
  //palettes of home coupons

    .gl-palette {
        &.coupon-box {
            .p-card-header {
                background-color: #ECF4FC;
                i {
                    background-image: url('/assets/images/gl-logo.png');
                    width: 121px;
                    height: 30px;
                }
            }
            &.large-layout {
                .p-card-body {
                    background: transparent linear-gradient(141deg, #3C8BD9 0%, #1E67AF 100%) 0% 0% no-repeat padding-box;
                }
            }
        }
        &.coupon-selector-item {
            .p-menuitem-content {
                &:hover {
                    background: #ECF4FC;
                }
            }
        }
        &.main-logo {
            background-image: url('/assets/images/gl-logo.png');
            width: 194px;
            height: 49px;
        }
    }

    .mc-palette {
        &.coupon-box {
            .p-card-header {
                background-color: #F1FBF9;
                i {
                    background-image: url('/assets/icons/mc-logo.svg');
                    width: 116px;
                    height: 39px;
                }
            }
            &.large-layout {
                .p-card-body {
                    background: transparent linear-gradient(101deg, #35867B 0%, #42C3B4 100%) 0% 0% no-repeat padding-box;
                }
            }
        }
        &.coupon-selector-item {
            .p-menuitem-content {
                &:hover {
                    background: #F1FBF9;
                }
            }
        }
        &.main-logo {
            background-image: url('/assets/icons/mc-logo.svg');
            width: 194px;
            height: 66px;
        }
    }

    .cr-palette {
        &.coupon-box {
            .p-card-header {
                background-color: rgb(255 237 229 / 50%);
                i {
                    background-image: url('/assets/images/cr-logo.png');
                    width: 109px;
                    height: 23px;
                }
            }
            &.large-layout {
                .p-card-body {
                    background: transparent linear-gradient(122deg, #FE5001 0%, #B83C03 100%) 0% 0% no-repeat padding-box;
                }
            }
        }
        &.coupon-selector-item {
            .p-menuitem-content {
                &:hover {
                    background: #FFF6F2;
                }
            }
        }
        &.main-logo {
            background-image: url('/assets/images/cr-logo.png');
            width: 206px;
            height: 43px;
        }
    }

    .rtb-palette {
        &.coupon-box {
            .p-card-header {
                background-color: $light-bg;
                //background-color: rgb(234 246 237 / 50%);
                i {
                    background-image: url('/assets/icons/rtb-logo.svg');
                    width: 114px;
                    height: 13px;
                }
            }
            &.large-layout {
                .p-card-body {
                    background: transparent linear-gradient(115deg, #000000 0%, #CF0000 100%) 0% 0% no-repeat padding-box;
                }
            }
        }
        &.coupon-selector-item {
            .p-menuitem-content {
                &:hover {
                    background: $light-bg;
                }
            }
        }
        &.main-logo {
            background-image: url('/assets/icons/rtb-logo.svg');
            width: 267px;
            height: 30px;
        }
    }

    .y-palette {
        &.coupon-box {
            .p-card-header {
                background-color: rgb(255 229 229 / 50%);
                i {
                    background-image: url('/assets/images/yx-logo.png');
                    width: 97px;
                    height: 53px;
                }
            }
            &.large-layout {
                .p-card-body {
                    background: transparent linear-gradient(129deg, #EF0404 0%, #6E0202 100%) 0% 0% no-repeat padding-box;
                }
            }
        }
        &.coupon-selector-item {
            .p-menuitem-content {
                &:hover {
                    background: #FFF2F2;
                }
            }
        }
        &.main-logo {
            background-image: url('/assets/images/yx-logo.png');
            width: 150px;
            height: 83px;
        }
    }

    .yt-palette {
        &.coupon-box {
            .p-card-header {
                background-color: #FFF0F0;
                i {
                    background-image: url('/assets/icons/youtube-full-logo.svg');
                    width: 108px;
                    height: 24px;
                }
            }
            &.large-layout {
                .p-card-body {
                    background: transparent linear-gradient(112deg, #FF0000 0%, #FF6767 100%) 0% 0% no-repeat padding-box;
                }
            }
        }
        &.coupon-selector-item {
            .p-menuitem-content {
                &:hover {
                    background: #FFF0F0;
                }
            }
        }
        &.main-logo {
            background-image: url('/assets/icons/youtube-full-logo.svg');
            width: 185px;
            height: 41px;
        }
    }

    .dv-palette {
        &.coupon-box {
            .p-card-header {
                background-color: rgb(234 246 237 / 50%);
                i {
                    background-image: url('/assets/images/dv-logo.png');
                    width: 134px;
                    height: 51px;
                }
            }
            &.large-layout {
                .p-card-body {
                    background: transparent linear-gradient(115deg, #1F8E3D 0%, #083A15 100%) 0% 0% no-repeat padding-box;
                }
            }
        }
        &.coupon-selector-item {
            .p-menuitem-content {
                &:hover {
                    background: #F4FAF6;
                }
            }
        }
        &.main-logo {
            background-image: url('/assets/images/display-full-logo.png');
            width: 150px;
            height: 83px;
        }
    }

    .mcard-palette {
        &.coupon-box {
            .p-card-header {
                background: transparent linear-gradient(63deg, #ffad204d 0%, #ff97b34d 100%) 0% 0% no-repeat padding-box!important;
                &::before {
                    content: '';
                    background-image: url('/assets/icons/credit-card.svg');
                    display: inline-block;
                    position: absolute;
                    width: 19px;
                    height: 15px;
                    left: 0;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    margin-top: 12px;
                    margin-left: 15px;
                }
                i {
                    background-image: url('/assets/icons/mastercard-full-logo.svg');
                    width: 72px;
                    height: 56px;
                    background-size: 100%;
                    background-repeat: no-repeat;
                }
            }
            &.large-layout {
                .p-card-header, .p-card-footer {
                    display: none;
                }
                .p-card-body {
                    .p-card-content {
                        height: 100%;
                    }
                    width: 100%!important;
                    color: #000000;
                    background: transparent linear-gradient(63deg, rgba(255, 173, 32, 0.3019607843) 0%, rgba(255, 151, 179, 0.3019607843) 100%) 0% 0% no-repeat padding-box !important;
                    .mcard-layout {
                        height: 100%;
                        position: relative;
                        .top-layout {
                            .text {
                                font-size: 10px;
                                letter-spacing: 2px;
                                color: #000000;
                            }
                            .icon {
                                background-image: url('/assets/icons/credit-card.svg');
                                display: inline-block;
                                width: 32px;
                                height: 26px;
                                background-size: 100%;
                                background-repeat: no-repeat;
                                margin-top: 16px;
                                margin-left: 19px;
                            }
                            .logo {
                                background-image: url('/assets/icons/mastercard-full-logo.svg');
                                width: 53px;
                                height: 41px;
                                display: inline-block;
                                background-size: 100%;
                                background-repeat: no-repeat;
                            }
                        }
                        .middle-layout {
                            color: #000000;
                            margin-top: 3px;
                            font-size: 24px;
                            letter-spacing: 3.6px;
                            text-align: center;
                        }
                        .bottom-layout {
                            color: #000000;
                            position: absolute;
                            width: 100%;
                            bottom: -35px;
                            .text {
                                letter-spacing: 2.8px;
                                color: #000000;
                                opacity: 0.75;
                                font-size: 14px;
                            }
                            .date-layout {
                                text-align: right;
                                letter-spacing: 1.6px;
                                color: #000000;
                                opacity: 0.75;
                                font-size: 8px;
                                line-height: 15px;
                                position: relative;
                                top: 6px;
                            }
                            .p-button {
                                padding-top: 2px;
                                .p-button-label {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.main-logo {
            background-image: url('/assets/icons/mastercard-full-logo.svg');
            width: 95px;
            height: 74px;
        }
    }
    .xndr-palette {
        &.coupon-box {
            .p-card-header {
                background-color: $light-bg;
                i {
                    background-image: url('/assets/icons/xandr-full-logo.svg');
                    width: 104px;
                    height: 27px;
                }
            }
            &.large-layout {
                .p-card-body {
                    background: transparent linear-gradient(130deg, #F73232 0%, #000000 100%) 0% 0% no-repeat padding-box;
                }
            }
        }
        &.coupon-selector-item {
            .p-menuitem-content {
                &:hover {
                    background: $light-bg;
                }
            }
        }
        &.main-logo {
            background-image: url('/assets/icons/xandr-full-logo.svg');
            width: 163px;
            height: 43px;
        }
    }
    .n-palette {
        &.coupon-box {
            .p-card-header {
                background-color: #FFF0F0;
                i {
                    background-image: url('/assets/icons/netflix-logo.svg');
                    width: 99px;
                    height: 27px;
                }
            }
            &.large-layout {
                .p-card-body {
                    background: #D81F25;
                }
            }
        }
        &.coupon-selector-item {
            .p-menuitem-content {
                &:hover {
                    background: #FFF0F0;
                }
            }
        }
        &.main-logo {
            background-image: url('/assets/icons/netflix-logo.svg');
            width: 162px;
            height: 44px;
        }
    }
    .in-palette {
        &.coupon-box {
            .p-card-header {
                background-color: #F4FAFF;
                i {
                    background-image: url('/assets/icons/linkedIn-logo.svg');
                    width: 119px;
                    height: 31px;
                }
            }
            &.large-layout {
                .p-card-body {
                    background: #006699;
                }
            }
        }
        &.coupon-selector-item {
            .p-menuitem-content {
                &:hover {
                    background: #F4FAFF;
                }
            }
        }
        &.main-logo {
            background-image: url('/assets/icons/linkedIn-logo.svg');
            width: 191px;
            height: 51px;
        }
    }
    .yh-palette {
        &.coupon-box {
            .p-card-header {
                background-color: #F9F4FF;
                i {
                    background-image: url('/assets/icons/yahoo-logo.svg');
                    width: 114px;
                    height: 46px;
                }
            }
            &.large-layout {
                .p-card-body {
                    background: #5F01D1;
                }
            }
        }
        &.coupon-selector-item {
            width: 74px!important;
            .p-menuitem-content {
                &:hover {
                    background: #F9F4FF;
                }
            }
        }
        &.main-logo {
            background-image: url('/assets/icons/yahoo-logo.svg');
            width: 202px;
            height: 83px;
        }
    }





  // Responsive screen

  @media only screen and (min-width: 1920px) {
    .coupon-layout .show-coupon .confetti-cover {
        max-width: 1200px;
        left: 373px;
        margin-left: 0!important;
    }
    .footer {
	    &[isEn=false] { //cn footer
            .middle-section {
                padding-left: 300px!important;
                padding-right: 290px!important;
            }
	    }
    }
  }

  @media only screen and (max-width: 1920px) {
    .coupon-layout.microsoft-coupon .show-coupon .confetti-cover {
        margin-left: -243px;
        max-width: 1200px;
    }
    .footer {
	    &[isEn=false] { //cn footer
            .middle-section {
                padding-left: 168px!important;
                padding-right: 170px!important;
            }
	    }
    }
  }

  @media only screen and (max-width: 1800px) {
    .coupon-layout.microsoft-coupon .show-coupon .confetti-cover {
        //left: 302px;
        //max-width: 1200px;;
    }
  }

  @media only screen and (max-width: 1600px) {
    .microsoft-coupon {
    .microsoft-image .image {
      background-image: url('/assets/images/mic-step1-m.png')!important;
      &.cn {
        background-image: url('/assets/images/mic-cn-step1-m.webp')!important;
      }
    }
    .show-coupon .image {
      background-image: url('/assets/images/mic-bg-step2-m.png')!important;
      &.cn {
        background-image: url('/assets/images/mic-cn-step2-m.webp')!important;
      }
    }
   }
   .footer {
	    &[isEn=false] { //cn footer
            .middle-section {
                padding-left: 155px!important;
                padding-right: 153px!important;
            }
	    }
    }
  }

  @media only screen and (max-width: 1440px) {
    .footer {
	    &[isEn=false] { //cn footer
            .middle-section {
                padding-left: 155px!important;
                padding-right: 153px!important;
            }
	    }
    }
  }

  @media only screen and (max-width: 1400px) {
    .footer {
	    &[isEn=false] { //cn footer
            .middle-section {
                padding-left: 100px!important;
                padding-right: 100px!important;
                .cn-sections {
                    .sections-box {
                        width: 37%!important;
                    }
                    .section {
                        //width: 12%!important;
                        &.section4, &.section5 {
                            width: 23%!important;
                        }
                    }
                }
            }
	    }
    }
  }

  @media only screen and (max-width: 1250px) {
    .footer .top-section, .footer .middle-section, .coupon-layout.microsoft-coupon .microsoft-image .image .on-top-shadow {
      padding-left: 10%!important;
    }
     .coupon-layout.microsoft-coupon .questions {
      //margin-left: 10%!important;
    }
    .footer {
	    &[isEn=false] { //cn footer
            .middle-section {
                .cn-sections {
                    .follow .icons a i {
                        margin-right: 14px!important;
                    }
                }
            }
	    }
    }
    .coupon-layout.google-coupon {
        .confetti-cover {
            top: -121px!important;
        }
    }
    .adcore-logo.last-step {
        margin-top: 50px;
        margin-bottom: 43px;
        a {
            position: static;
        }
    }
  }

  @media only screen and (max-width: 1200px) {
    .microsoft-coupon {
      .microsoft-image .image {
        background-image: url('/assets/images/mic-step1-s.png')!important;
        &.cn {
            background-image: url('/assets/images/mic-cn-step1-s.webp')!important;
          }

      }
      .show-coupon .image {
        background-image: url('/assets/images/mic-bg-step2-s.png')!important;
        &.cn {
            background-image: url('/assets/images/mic-cn-step2-s.webp')!important;
          }
      }
     }
     .coupon-layout .confetti-cover {
        width: 120%!important;
        margin-left: -102px;
     }
  }
  @media only screen and (min-width: 1100px) {
    .big-screen-layout {
        display: block;
    }
    .small-screen-layout {
        display: none;
    }
  }
  @media only screen and (max-width: 1100px) {
    .footer {
        .top-section, .middle-section {
          padding-left: 8%!important;
          padding-right: 7%;
        }
        .middle-section .follow .icons a i {
           margin-right: 16px!important;
        }
        &[isEn=false] { //cn footer
            .middle-section {
                padding-top: 41px!important;
                    padding-left: 80px!important;
                    padding-right: 80px!important;
                .cn-sections {
                    .section5 {
                        .logo {
                            width: 181px!important;
                            height: 66px!important;
                            background-size: 100%;
                        }
                        .qr i {
                            width: 118px!important;
                            height: 117px!important;
                            background-size: 100%;
                        }
                    }
                }
            }
	    }
    }
    .xndr-layout-addition, .yh-layout-addition, .n-layout-addition, .in-layout-addition {
        .powered .powered-position .info {
            max-width: 550px;
            padding-right: 30px !important;
        }
    }
    .coupon-layout.microsoft-coupon .powered .powered-position .info {
        max-width: 550px;
        padding-right: 30px !important;
    }
    .coupon-layout .powered-colored-layout .powered {
        height: 463px!important;
        .info {
            width: 100%!important;
            text-align: center!important;
            padding-right: 0!important;
            .text {
                display: none!important;
            }
            .title {
                width: 204px;
                display: inline-block;
            }
            .link-btn {
                width: 196px;
                margin-bottom: 34px;
            }
        }
        .buttons {
            display: block!important;
        }
        .badget.home-align div {
            .gl-tag {
                width: auto!important;
                        margin-right: 8px!important;
            }
            &:nth-child(2), &:nth-child(3) {
                a {
                  &:nth-child(1),&:nth-child(2) {
                    transform: scale(0.92);
                    margin-right: -5px!important;
                  }
                }
            }
            a {
                margin-bottom: 4px!important;
            }
        }
    }
    .big-screen-layout {
        display: none;
    }
    .small-screen-layout {
        display: block;
    }
   }

   @media only screen and (min-width: 950px) {
    .contact, .about {
        &.mobile {
            display: none!important;
        }
        &.desk {
            display: inline-block!important;
        }
    }
    app-google-coupon-step3 .popular-coupon h3, app-cn-google-coupon-step3 .popular-coupon h3{
        font-size: 20px;
    }
   }

  //Ipad
   @media only screen and (max-width: 950px) {
    .coupon-layout {
        .apps {
            //padding-bottom: 63px!important;
        }
        .questions, &.microsoft-coupon .questions, &.google-coupon .questions {
            max-width: 100%!important;
            padding: 0 60px;
            margin-left: 0!important;
            padding-bottom: 63px!important;
        }
        .footer {
            .top-section {
                height: 100px!important;
                padding-top: 25px!important;
                .contact, .about {
                    width: 50%!important;
                    &.mobile {
                        display: inline-block!important;
                    }
                    &.desk {
                        display: none!important;
                    }
                    a {
                        text-decoration: none;
                    }
                }
                .title {
                    text-align: center;
                    i {
                        width: 18px!important;
                        height: 18px!important;
                    }
                    span {
                        display: block;
                        padding-top: 6px;
                    }
                }
                .content {
                    display: none;
                }
                .image {
                    display: none!important;
                }
            }
            .middle-section {
                height: 200px!important;
                padding: 30px 60px !important;
                .logo {
                    display: none!important;
                }
                .follow {
                    width: 50%!important;
                    padding-bottom: 17px!important;
                    padding: 0!important;
                    .icons {
                        //width: 160px;
                        a i {
                            margin-right: 19px;
                            margin-bottom: 15px;
                        }
                    }
                }
                .apps {
                    width: 50%!important;
                    padding: 0!important;
                    padding-bottom: 17px!important;
                    text-align: right!important;
                    .icons {
                        a i {
                            margin-right: 12px;
                            margin-bottom: 7px;
                        }
                    }
                    .title {
                        padding-right: 135px;
                    }
                }
                .logo-mobile {
                    display: block!important;
                    padding: 0;
                    text-align: center;
                    width: 100%!important;
                    margin-top: 12px;
                    padding-top: 0!important;
                }
            }
            &[isen="false"] {
                .apps .title {
                    padding-right: 101px;
                }
                .follow .title {
                    margin-left: -2px;
                }
            }
            &[isEn=false] .middle-section {
                padding-left: 50px !important;
                padding-right: 50px !important;
                height: 370px!important;
                .section {
                    &.section3 {
                        width: 14%!important;
                    }

                    &.section5 {
                        width: 24% !important;
                        .logo {
                            display: inline-block!important;
                        }
                    }
                }
            }
        }
        &.google-coupon {
            .link-acc {
                .step1 {
                    .text {
                        width: 87px;
                        left: -30px;
                    }
                }
                .step2 {
                    .text {
                        width: 98px;
                        left: -35px;
                    }
                }
            }
        }
        &.microsoft-coupon {
            .video-mobile-view {
                margin-bottom: 0;
            }
            .microsoft-image .image {
              background-image: url('/assets/images/mic-bg-step1-xs.png')!important;
              &.cn {
                background-image: url('/assets/images/mic-bg-cn-step1-xs.webp')!important;
              }
              .shadow {
                background: transparent;
              }
              .on-top-shadow {
                padding-left: 60px !important;
              }
            }
            .show-coupon {
                .on-top-shadow .microsoft-coupon-image {
                    width: 540px!important;
                    height: 252px!important;
                  }
                  .microsoft-image {
                    height: 930px;
                  }
                  .image {
                    background-image: url('/assets/images/mic-bg-step2-xs.png')!important;
                    &.cn {
                      background-image: url('/assets/images/mic-bg-cn-step2-xs.webp')!important;
                    }
                  }
            }
            .content {
                font-size: 26px!important;
                span.bold.sign {
                    font-size: 15px!important;
                    left: 0px!important;
                    top: -9px!important;
                }
            }
            .powered .powered-position {
                .info {
                    width: calc(100% - 350px) !important;
                    padding-right: 40px !important;
                }
            }
        }
        &.xndr-layout-addition, &.yh-layout-addition, &.n-layout-addition, &.in-layout-addition {
            .powered .powered-position .info {
                max-width: 550px;
                padding-right: 30px !important;
            }
        }
        .confetti-cover {
            width: 138%!important;
            margin-left: -149px;
         }
         &.fixed-header {
            .header {
                .col.h-full {
                    width: 100%!important;
                    padding-left: 16px!important;
                    padding-top: 17px!important;
                    &:not(.logo):not(.coupon-menu).text-right {
                        width: 30px!important;
                    }
                }
                .coupon-menu, .links {
                    display: none;
                }
                .logo {
                    border-right: none;
                    padding-top: 0!important;
                }
                .mobile-menu {
                    display: inline-block;
                    position: relative;
                    top: -1px;
                    right: 17px;
                }
            }
            .main-content {
                h1 {
                    font-size: 26px!important;
                }
                h2 {
                    font-size: 16px!important;
                    margin-bottom: 25px!important;
                }
            }
         }
         .coupon-box-small-layout {
            padding-left: 50px;
            padding-right: 50px;
         }
         .coupon-box {
            width: 160px!important;
            height: 188px!important;
            margin-left: 7px!important;
            margin-right: 7px!important;
            &:not(.large-layout) {
                .p-card-header {
                    height: 75px;
                }
                .p-card-body {
                    height: calc(100% - 75px);
                    .p-card-content {
                        padding-right: 2px;
                        padding-left: 10px;
                    }
                }
            }
            &.large-layout {
                width: 332px!important;
                height: 177px!important;
                .p-card-header {
                    width: 156px!important;
                }
                .p-card-body {
                    width: calc(100% - 156px)!important;
                    .p-card-content {
                        padding-left: 16px!important;
                        padding-top: 22px!important;
                    }
                    .price {
                        font-size: 26px!important;
                    }
                    .desc {
                        font-size: 13px!important;
                    }
                }
                .p-card-footer {
                    padding-left: 16px!important;
                    .p-button.p-button-link .p-button-label {
                        font-size: 13px!important;
                    }
                }
                &.mcard-palette {
                    .p-card-body {
                        width: 100%!important;
                        .top-layout {
                            .logo {
                                width: 44px;
                            }
                            .icon {
                                width: 28px;
                                margin-top: 12px;
                                margin-left: 10px;
                            }
                        }
                        .middle-layout {
                            font-size: 20px;
                            margin-top: -5px;
                        }
                        .bottom-layout {
                            //bottom: -4px;
                            .date-layout {
                                top: 0;
                            }
                            .text {
                                font-size: 12px;
                                padding-top: 0;
                            }
                            .p-button .p-button-label {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
         }
         .coupon-box-large-layout {
            text-align: center;
            display: inline-block;
            .p-carousel {
                max-width: 761px;
            }
         }
    }
    .footer {
        margin-top: 0!important;
    }
    .google-ad {
        margin-top: 0;
        margin-bottom: 111px;
    }
    .video-mobile-view {
        display: block;
        .video-dialog {
            position: absolute;
            left: 0;
            top: 0;
            width: 441px!important;
            max-width: 441px;
            min-width: 441px;
            height: 271px;
            max-height: 271px;
            min-height:  271px;
            .p-dialog-header-icons {
                display: none;
            }
            .resize-btn {
                display: none;
            }
            .p-dialog-header {
                text-align: center;
                width: 100%;
                display: block;
            }
        }
        .p-dialog-mask {
            position: absolute;
        }
     }
     .video-desk-view {
        display: none;
     }
     .main-logo {
        margin-top: 0!important;
    }
    .coupon-box:not(.large-layout).mc-palette .p-card-header::before {
        width: 57px!important;
        height: 16px!important;
    }
    app-google-coupon-step3 .popular-coupon h3, app-cn-google-coupon-step3 .popular-coupon h3 {
        font-size: 16px;
    }

   }

   @media only screen and (max-width: 850px) {
    .explore-title {
        margin-top: 25px!important;
    }
    .apps {
        width: 580px!important;
    }
    .adcore-logo {
        margin-top: 50px;
        a {
            i {
                width: 80px!important;
                height: 20px!important;
            }
        }
    }
    .coupon-layout .footer[isEn=false] .middle-section {
            height: 1080px!important;
            .sections-box {
                margin-bottom: 30px;
            }
            .cn-sections .section {
                height: auto!important;
                display: block!important;
                width: 100%!important;
                &.section3 {
                    margin-bottom: 30px;
                }
                &.section4 {
                    margin-bottom: 45px;
                }
                &.section5 {
                    text-align: center;
                    .qr {
                        i {
                            float: none!important;
                            display: inline-block!important;
                            width: 221px!important;
                            height: 221px!important;
                        }
                    }
                    .icons {
                        width: 100%;
                        display: inline-block;
                        text-align: center!important;
                        .follow {
                            .icons {
                                top: 18px;
                            }
                            width: auto !important;
                            a i {
                                margin-right: 22px !important;
                            }
                        }
                    }
                    .logo {
                        float: none!important;
                        width: 195px !important;
                        margin-top: 28px;
                    }
                }
            }
            .sections-box {
                width: 100%!important;
                .section {
                    width: 50%!important;
                    display: inline-block!important;
                }
            }

    }
    .coupon-layout.google-coupon {
        .adcore-logo a {
            position: static;
        }
        .google-tag {
            //margin-top: 38px;
            margin-top: 0;
        }
        .powered .powered-position {
            margin: 0 60px!important;
            .info {
                padding-right: 50px!important;
            }
        }
        .get-coupon-btn {
            display: block;
        }
        .another-acc-btn {
            margin-top: 6px;
        }
        .or {
            &.desktop {
                display: none!important;
            }
            &.mobile {
                //display: block!important;
                margin: 0!important;
            }
        }
        .apps-main-title {
            margin-top: 27px;
        }
    }
    .coupon-layout.microsoft-coupon  {
        &.last-step {
            .microsoft-image {
                //height: 820px;
            }
        }
        .powered .powered-position {
            .buttons {
                display: none;
            }
        }
    }
    .works-steps {
        h2 {
            margin-bottom: 25px!important;
        }
        .arrow {
            transform: rotate(90deg);
            margin-top: 20px!important;
            margin-bottom: 17px;
            background-size: 72%;
            background-repeat: no-repeat;
            margin-left: -5px;
        }
        .flex {
            display: block!important;
        }
        .text {
            display: none;
        }
        .col {
            i {
                margin-bottom: 9px!important;
            }
            &.coupon i {
                width: 49px;
                height: 49px;
            }
            &.acc i, &.redeem i {
                width: 43px;
                height: 43px;
            }
        }
    }
    .coupon-layout.fixed-header {
        .works-steps {
            padding-bottom: 40px!important;
        }
        .questions {
            padding-bottom: 0!important;
        }
    }
    .coupon-layout.google-coupon {
        .steps .line {
            //width: 250px!important;
        }
    }
    .acc-loader-blue {
        margin-top: 20px;
    }
    .coupon-layout {
        &.xndr-layout-addition, &.yh-layout-addition, &.n-layout-addition, &.in-layout-addition {
            .acc-loader-blue {
                margin-top: 20px!important;
            }
        }
    }
   }

   @media only screen and (max-width: 800px) {
    .video-player  {
        width: 500px;
        height: 281px;
    }
    .coupon-layout .coupon-box-small-layout {
        padding-left: 120px;
        padding-right: 120px;
    }
    .coupon-box-large-layout {
        .p-carousel-content {
            max-width: 410px;
        }
    }
   }
   @media only screen and (max-width: 650px) {
    .coupon-layout .coupon-box-small-layout {
        padding: 0 50px;
    }
    .coupon-layout-home-body .p-carousel {
        //width: 440px;
        display: inline-block;
    }
    .coupon-layout .powered-colored-layout .powered {
        .powered-position {
            margin: 0 3% !important;
        }
    }
    .coupon-layout.google-coupon .link-acc .steps .line {
        width: 350px!important;
    }
    app-google-coupon-step3 .popular-coupon, app-cn-google-coupon-step3 .popular-coupon {
        margin-top: 43px !important;
        h3 {
            margin-bottom: 15px !important;
        }
    }
   }
   @media only screen and (max-width: 600px) {
    .items-app {
        .p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
           display: none;
        }
    }
    .apps {
        padding: 0 48px;
    }
    .coupon-layout .confetti-cover {
        width: 158%!important;
        margin-left: -219px;
     }
     .video-mobile-view {
        height: 227px;
        width: 361px;
        margin-top: 23px;
        margin-bottom: 23px;
        .video-dialog {
            width: 332px!important;
            max-width: 332px;
            min-width: 332px;
            height: 208px;
            max-height: 208px;
            min-height:  208px;
        }
     }
     .google-ad-text {
        font-size: 14px;
        width: 332px!important;
        text-align: left;
        color: $diabled-text;
        .space {
            //display: block;
        }
        .mobile-label {
            display: inline;
        }
        .desktop-label {
            display: none;
        }
     }
     .video-player {
        width: 100%;
        //height: 350px;
        margin-bottom: 47px;
     }
     .coupon-selection-layout .desktopView {
        display: none!important;
     }
     .coupon-selection-layout {
        .mobileView {
            display: block!important;
            .p-carousel {
                display: flex!important;
            }
        }
        .primary-btn.select-coupon {
            margin-top: 37px!important;
        }
     }

   }

   @media only screen and (max-width: 750px) {
    .coupon-layout {
        .footer {
            .middle-section {
                height: 234px!important;
                .follow {
                    width: calc(100% - 130px)!important;
                }
                .apps {
                    text-align: left!important;
                    width: 120px!important;
                    position: relative;
                    right: -9px;
                    .title {
                        padding-right: 0
                    }
                }
                .icons {
                    width: 160px;
                }
            }
        }
        .content {
            font-size: 26px!important;
            span.bold.sign {
                font-size: 15px!important;
                left: 0px!important;
                top: -9px!important;
            }
        }
        .metrics {
            padding-left: 40px;
            padding-right: 40px;
        }
        &.google-coupon {
            &.cn {
                .buttons {
                    margin-top: 73px !important;
                }
            }
            .google-tag i {
                width: 112px;
                height: 106px;
            }
            .content span {
                width: 350px!important;
            }
            &.yh-layout-addition .content span.last {
                margin-left: -10px;
            }
            .link-acc {
                .steps {
                    margin-top: 46px;
                    .line {
                        //width: 400px!important;
                    }
                }
                .middle-text {
                    .text {
                        width: 150px;
                        left: -29px;
                        text-align: left;
                    }
                }
            }
            .content {
                width: 312px!important;
            }
            .buttons {
                margin-top: 45px!important;
                .text {
                    width: 270px;
                    text-align: center;
                    display: inline-block;
                    font-size: 14px;
                    line-height: 18px;
                }
                .link-btn {
                    //margin-top: 20px;
                }
            }
            .show-coupon {
                .image {
                    width: 540px!important;
                    height: 290px!important;
                    margin-bottom: -20px;
                    margin-top: -75px;
                }
            }
        }
        &.microsoft-coupon {
            &.last-step {
                .microsoft-image {
                    //height: 840px!important;
                    .adcore-logo {
                        margin-bottom: 30px;
                        margin-top: -15px;
                    }
                    .get-coupon-btn {
                        display: block;
                    }
                    .or {
                        display: none!important;
                    }
                }
            }
            .microsoft-image {
                text-align: center;
                .adcore-logo {
                    margin-bottom: 40px;
                    a {
                        position: initial;
                    }
                }
                .image {
                    background-image: url('/assets/images/mic-bg-step1-xxs.png')!important;
                    padding-top: 50px;
                    &.cn {
                        background-image: url('/assets/images/mic-bg-cn-step1-xxs.webp')!important;
                      }
                    .on-top-shadow {
                        padding-left: 0!important;
                        .microsoft-tag-box {
                            display: block;
                            .microsoft-tag {
                                width: 185px;
                                height: 64px;
                                margin-right: 0;
                                margin-bottom: 5px;
                            }
                        }
                        .content {
                            margin-top: 30px;
                            margin-bottom: 15px;
                        }
                        .microsoft-ad-text {
                            width: 338px;
                            text-align: left;
                            font-size: 14px;
                            line-height: 18px;
                            button {
                                font-size: 14px!important;
                            }
                        }
                    }
                }

                .link-acc {
                    .steps {
                        margin-top: 36px;
                        margin: 0 auto;
                        left: -12px;
                        .step .text {
                            margin-top: 9px;
                        }
                        .step1 {
                            .text {
                                width: 100px;
                                text-align: left;
                                left: -21px;
                            }
                        }
                        .line {
                            width: 206px;
                        }
                        .step2 {
                            .text {
                                left: -67px;
                            }
                        }
                    }
                    &.cn .steps {
                        .step1 .text {
                            width: 135px;
                            left: -47px;
                        }
                        .step2 .text {
                            width: 100px;
                            left: -5px;
                        }
                    }
                }
                .loader-box.acc.green {
                    left: -23px;
                    margin-top: 72px;
                }
                .buttons {
                    .create-btn {
                        position: initial!important;
                        margin-left: 0!important;
                        margin-top: 4px;
                        display: block;
                    }
                    .link-btn {
                        margin-bottom: 18px;
                    }
                    .text {
                        display: block;
                        margin-bottom: 10px;
                        color: $diabled-text;
                        &.redeem {
                            display: inline-block;
                            line-height: 18px;
                            width: 310px;
                            color: white;
                        }
                    }
                }
            }
            .show-coupon {
                .image {
                    background-image: url('/assets/images/mic-bg-step2-xxs.png')!important;
                    &.cn {
                        background-image: url('/assets/images/mic-bg-cn-step2-xxs.webp')!important;
                      }
                }
                .buttons .link-btn {
                    margin-top: 29px;
                }
            }
            .powered {
                height: 169px!important;
                .powered-position {
                    left: 0;
                    padding-top: 0!important;
                    margin: 0!important;
                    .badget {
                        display: block!important;
                        width: 100%;
                        position: absolute;
                        margin-top: 25px!important;
                        a.microsoft-tag {
                            display: inline-block!important;
                            &:last-child {
                                i {
                                    margin-left: 6px!important;
                                }
                            }
                        }
                        i {
                            width: 142px!important;
                            height: 49px!important;
                        }
                    }
                    .info {
                        width: 100%!important;
                        max-width: 100%;
                        padding-right: 0!important;
                        padding-top: 98px;
                        display: block!important;
                        .title {
                            width: 258px;
                            margin: 0 auto;
                            text-align: center;
                        }
                        .text {
                            display: none;
                        }
                    }
                    .buttons {
                        display: none;
                    }
                }
            }
            .video-mobile-view {
                width: 332px;
                height: 227px;
                .video-dialog {
                    width: 332px!important;
                    max-width: 332px;
                    min-width: 332px!important;
                    height: 208px;
                    max-height: 208px;
                    min-height:  232px;

                }
             }
        }
        &.xndr-layout-addition, &.yh-layout-addition, &.n-layout-addition, &.in-layout-addition {
            .powered {
                height: 169px!important;
                .powered-position {
                    left: 0;
                    padding-top: 0!important;
                    margin: 0!important;
                    .badget {
                        display: block!important;
                        width: 100%;
                        position: absolute;
                        margin-top: 25px!important;
                        a.microsoft-tag {
                            display: inline-block!important;
                            &:last-child {
                                i {
                                    margin-left: 6px!important;
                                }
                            }
                        }
                        i {
                            width: 142px!important;
                            height: 49px!important;
                        }
                    }
                    .info {
                        width: 100%!important;
                        max-width: 100%;
                        padding-right: 0!important;
                        padding-top: 98px;
                        display: block!important;
                        .title {
                            width: 258px;
                            margin: 0 auto;
                            text-align: center;
                        }
                        .text {
                            display: none;
                        }
                    }
                    .buttons {
                        display: none;
                    }
                }
            }
        }
    }
    .loader-box.blue {
        top: -46px;
    }
    .acc-loader-green {
        position: relative;
        left: -11px;
    }
    .acc-loader-blue {

    }
    .powered {
        .powered-position {
            padding-top: 7%!important;
        }
    }
    .acc-loader-blue {
        margin-top: 40px;
    }
   }

   @media only screen and (min-width: 650px) {
    .coupon-layout {
        position: relative;
    }
    .google-ad {
        height: 308px;
        position: fixed;
        margin: 0;
        bottom: 25px;
        right: 39px;
        background: white;
        z-index: 5;
        .google-ad-position {
            margin: 0;
        }
    }
   }

   @media only screen and (max-width: 650px) {
    .coupon-layout.microsoft-coupon .microsoft-image .link-acc .steps.with-middle-text .line {
        width: 350px;
    }
    .coupon-layout.microsoft-coupon .show-coupon .microsoft-image {
        height: 850px;
        .image {
            padding-top: 60px!important;
        }
    }
    .coupon-layout.microsoft-coupon .show-coupon .image .on-top-shadow {
        .microsoft-coupon-image {
            width: 450px !important;
            height: 230px !important;
        }
        .content {
            margin-top: -6px;
        }
    }
    .coupon-layout.google-coupon {
        .show-coupon {
            .image {
                width: 450px !important;
                height: 230px !important;
                margin-bottom: 0px;
            }
        }
    }
    .google-ad {
        .google-ad-position {
            width: 100%;
            padding: 0 30px;
            float: none;
            height: 100%;
            .view {
                //height: calc(150px + 25%);
            }
        }
        height: 300px;
        margin-bottom: 20px;
    }
    .metrics {
        padding: 0!important;
        .item {
            width: 50%!important;
            vertical-align: top;
            &.linked-acc {
                display: none!important;
            }
            .amount {
                font-size: 26px!important;
            }
        }
    }
    .powered {
        height: 145px!important;
        .powered-position {
            margin: 0 60px !important;
            padding-top: 32px!important;
            .info {
                width: calc(100% - 27px - 60px)!important;
                padding-right: 20px!important;
                .title {
                    width: 198px;
                    margin-top: 7px;
                }
            }
            .text, .buttons {
                display: none;
            }
            .badget {
                vertical-align: top!important;
                .google-tag i {
                    width: 84px!important;
                    height: 80px!important;
                }
            }
        }
    }
   }

   @media only screen and (max-width: 500px) {
    .google-ad-text {
        width: auto!important;
        padding: 0 28px;
    }
    .coupon-layout.microsoft-coupon .microsoft-image .link-acc .steps.with-middle-text .line {
        width: 250px;
    }
    .content.bold {
        font-size: 23px!important;
    }
    .coupon-layout {
        .coupon-box-small-layout {
            padding: 0px;
        }
        .coupon-box-large-layout {
            width: 100% !important;
            padding-left: calc(50% - 175px);
            .p-carousel-prev, .p-carousel-next {
                display: none;
            }
            .p-carousel-items-container {
                //width: calc(100% - 41px);
                width: calc(100% - 65px);
            }
            .coupon-box.large-layout {
                //width: 100% !important;
                margin-left: 0!important;
                margin-right: 0!important;
            }
        }
        .popular-coupon .coupon-box-large-layout {
            width: auto !important;
            padding-left: initial !important;
        }
    }
    .coupon-selection-dialog{
        width: 100%;
        height: 100%;
        max-height: 100%;
        border: none;
        border-radius: 0;
        .content {
            padding: 0 29px;
            .coupon-box-dialog-layout {
                text-align: center;
            }
            .coupon-box.large-layout {
                width: 279px;
                height: 121px;
            }
        }
        .footer {
            padding: 0 29px;
        }
    }
    .create-acc-dialog {
        width: 100%!important;
        height: 100%;
        max-height: 100%;
        max-width: 100%!important;
        .p-dialog-header {
            border-top-right-radius: 0px !important;
            border-top-left-radius: 0px !important;
        }
        .p-dialog-content:last-of-type {
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
        }
        input, .p-dropdown {
            width: 100%!important;
        }
        .p-chips , .p-chips-multiple-container {
            width: 100%;
        }
    }
    .steps .middle-text {
        left: calc(50% - 15px)!important;
    }
    .loader-box.acc {
        width: 100%;
    }
    .acc-loader-green {
        left: -23px;
    }
    .coupon-layout.google-coupon .link-acc .steps .line {
        width: 250px!important;
    }
    .logo-via-mic {
        margin-top: 0!important;
    }
    .buttons .additional-text {
        margin-top: 90px!important;
    }
}

   //Mobile
   @media only screen and (max-width: 450px) {
    .coupon-layout {
        .footer {
            //margin-top: 63px!important;
            .top-section .title span {
                font-size: 15px;
            }
            .middle-section {
                padding: 30px 40px !important;
            }
            .bottom-section {
                padding: 15px;
                padding-top: 16px!important;
                span {
                    display: block;
                    &.separator {
                        display: inline-block;
                    }
                }
                .links {
                    margin: 0;
                    margin-top: 7px;
                    margin-left: 0!important;
                }
            }
        }
        .questions {
            max-width: 100%!important;
            padding: 0 30px!important;
            margin-top: 44px!important;
        }
        .apps {
            padding: 0 27px;
            width: 546px !important;
        }
    }
    .coupon-layout.google-coupon {
        .google-tag {
            //margin-top: 66px;
            i {
                width: 112px;
                height: 106px;
            }
        }
        .powered {
            .powered-position {
                margin: 0 30px !important;
            }
        }

        .link-acc .steps {
            margin-top: 46px;
            .line {
                width: 350px;
            }
        }
        .buttons {
            padding: 0;
            margin-top: 35px;
            .link-btn {
                width: 100%;
            }
        }

        .show-coupon .image {
            width: 113%!important;
            height: 260px !important;
            margin-left: -7%;
            margin-bottom: -3%;
            .confetti-cover {
                //display: none;
            }
        }
    }
    .coupon-layout.microsoft-coupon {
        .microsoft-image .image {
            background-image: url('/assets/images/mic-bg-step1-xxxs.png')!important;
            &.cn {
                background-image: url('/assets/images/mic-bg-cn-step1-xxxs.webp')!important;
              }
          }
          .show-coupon {
            .microsoft-image {
                .image {
                    //height: 454px;
                    background-image: url('/assets/images/mic-bg-step2-xxxs.png')!important;
                    //padding-top: 60px;
                    //padding-top: 90px!important;
                    &.cn {
                        background-image: url('/assets/images/mic-bg-cn-step2-xxxs.webp')!important;
                      }
                    .on-top-shadow {
                        .microsoft-coupon-image {
                            width: 118%!important;
                            margin-left: -9%;
                        }
                        .content {
                            margin-top: 1%;
                        }
                    }
                }
            }
            .buttons .link-btn {
                margin-top: 27px;
            }
          }
          .content.bold {
            font-size: 19px!important;
          }
          .link-acc.cn .line {
            width: 200px!important
        }
    }
    .google-ad {
        height: 240px;
        margin: 0;
    }
    .video-mobile-view {
        width: 332px;
        height: 227px;
        .video-dialog {
            width: 332px!important;
            max-width: 332px;
            min-width: 332px!important;
            height: 208px;
            max-height: 208px;
            min-height:  208px;

        }
     }
     .cn .steps {
        .text {
            font-size: 14px;
        }
    }
    .acc-loader-blue {
        position: relative;
        left: -10px;
    }
   }

   @media only screen and (max-width: 390px) {
    .coupon-layout.microsoft-coupon .microsoft-image .link-acc .steps {
        left: -6px;
        &.with-middle-text .line {
            width: 240px;
        }
    }
    .coupon-layout.microsoft-coupon .show-coupon .microsoft-image {
        .image {
            //padding-top: 93px;
            .on-top-shadow .content {
                margin-top: -3%;
            }
        }
    }
    .coupon-layout.google-coupon .show-coupon .image {
        transform: matrix(1, 0.07, -0.07, 1, 0, 0);
        height: 200px!important;
        width: 350px !important;
        margin: 0 auto;
        margin-top: -53px;
        margin-bottom: -6px;
    }
    .coupon-layout .confetti-cover {
        width: 190%!important;
        margin-left: -219px;
     }
     .loader-box {
        left: 3px;
        top: -79px;
        width: 100%;
        &.blue {
            top: -114px;
        }
    }
    .open-recaptcha, .create-acc-form-dialog {
        max-width: 315px;
        .p-dialog-header {
            padding-left: 28px !important;
        }
        .p-dialog-content {
            padding: 0 20px 30px 27px !important;
        }
    }
    .powered.home-layout .coupon-tags {
        a {
            width: 152px !important;
            height: 64px !important;
            &.gl-tag {
                width: auto!important;
                i {
                    width: 70px!important;
                    height: 67px!important;
                }
            }
        }
    }
    .acc-loader-green {
        top: 57px;
        left: -17px;
    }
    .acc-loader-blue {
        margin-top: 57px;
    }
    .coupon-layout.google-coupon {
        .steps .line {
            width: 206px!important;
        }
    }
    .coupon-layout.google-coupon .link-acc .steps .line {
        width: 206px!important;
    }
    .acc-loader-blue {
        margin-top: 92px;
    }
   }

   @media only screen and (max-width: 380px) {
    .coupon-layout.microsoft-coupon .microsoft-image .link-acc .steps {
        &.with-middle-text .line {
            width: 200px!important;
        }
    }
    .steps {
        .middle-text {
            .text {
                width: 120px;
            }
        }
    }
   }

   @media only screen and (max-width: 370px) {
    .coupon-layout {
        .coupon-box-large-layout {
            padding-left: calc(50% - 158px)!important;
            .p-carousel-items-container {
                width: calc(100% - 96px) !important;
            }
        }
        .coupon-box {
            width: 140px !important;
            &.large-layout {
                width: 297px !important;
            }
        }
        .coupon-selection-layout .mobileView {
            .coupon-box:not(.large-layout) {
                width: 160px !important;
            }
        }
    }
   }

   @media only screen and (max-width: 365px) {
    //cn footer
    .coupon-layout .footer[isEn=false] .middle-section {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
    .create-acc-dialog {
        .p-dialog-header {
            //padding: 31px 5% 10px 5%!important;
        }
        .p-dialog-content {
            //padding: 0 5% 30px 5%!important;
        }
        .secondary-btn {
            padding-left: 19px;
        }
    }
    .powered .powered-position .info .title {
        width: 170px;
        margin-top: -5px;
    }
    .coupon-layout .powered-colored-layout .powered {
        .badget.home-align div {
            a {
                margin-bottom: -2px !important;
            }
            &:nth-child(2), &:nth-child(3) {
                a {
                  &:nth-child(1),&:nth-child(2) {
                    transform: scale(0.82);
                    margin-right: -20px !important;
                  }
                }
            }
        }
    }
   }

   @media only screen and (max-width: 350px) {
    .link-acc .line {
        width: 150px!important
    }
    .coupon-layout.microsoft-coupon .show-coupon .microsoft-image {
        .image {
            .on-top-shadow .content {
                margin-top: -10%;
            }
        }
    }
    .google-ad {
        height: 200px;
    }
   }



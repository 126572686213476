$main-text-color:#202124;
$diabled-text: #9A9A9A;
$border:#D5D5D5;
$footer-text:#444444;
$footer-top-bg:#EBEBEB;
$footer-bottom-bg:#F5F5F5;
$grey-text: #BFBFBF;
$light-bg:#FFF1EF;

$gl-color: #1A73E8;
$gl-color-dark: #2C5EB7;
$mc-color: #34A852;
$mc-color-dark: #008575;
$yt-color:#FF0000;
$yt-color-dark:#E30000;
$n-color:#D81F26;
$n-color-dark:#C11017;
$in-color:#026699;
$in-color-dark:#005480;
$yh-color:#39017C;
$yh-color-dark:#2D045D;
$xndr-color:#FC5047;
$xndr-color-dark:#EB3228;

$error-message: #F8D7DA;
$error-message-border: #F6C6CA;

$main-text-font-size: 16px;